import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [
  { label: "Hydrocodone", value: "Hydrocodone" },
  { label: "Norhydrocodone", value: "Norhydrocodone" },
];

const aboutTheData = (
  <>
    <p>
      Hydrocodone is a semi-synthetic opioid used to treat moderate to severe
      pain and as a cough suppressant. It is commonly prescribed in pill form in
      combination with acetaminophen or ibuprofen. Life-threatening overdoses
      are possible, and prolonged use can result in physical dependence.
      Hydrocodone and one of its metabolites, norhydrocodone, are excreted in
      urine and moderately stable in wastewater.
    </p>
    <p>
      Prescription doses typically range from 10 mg to 20 mg per administration
      for pain relief, with a maximum daily dose of 60 mg.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const HydrocodonePage = () => {
  const { data, isLoading } = api.data.hydrocodoneWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default HydrocodonePage;
