import { bisector, group } from "d3-array";
import { useMemo } from "react";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/unbound-method */

export type Accessor<Datum, TOut = any> = (d: Datum) => TOut;
export type DefinedAccessor<Datum> = Accessor<Datum, boolean>;

type InferOutput<Datum, Val, Default = never> = Val extends keyof Datum
  ? Datum[Val]
  : Val extends Accessor<Datum>
    ? ReturnType<Val>
    : Default;

export interface SeriesInput<
  Datum,
  K extends string,
  X extends Accessor<Datum> | keyof Datum,
  Y extends Accessor<Datum> | keyof Datum,
  Y0 extends Accessor<Datum> | keyof Datum,
  XIdx extends number,
  YIdx extends number,
> {
  /** an identifier for this series */
  seriesKey: K;
  /** An optional label used for this series (default: seriesKey) */
  label?: string;
  /** the original data */
  data: Datum[];
  /* the property (or function) used for x values */
  x: X;
  /* the property (or function) used for y values */
  y: Y;
  /* the property (or function) used for the bottom y value (default: 0) */
  y0?: Y0;
  /**
   * the property (or function) used to determine if a value is defined
   * (defaults to the y value)
   */
  defined?: keyof Datum | DefinedAccessor<Datum>;
  /** index of the x scale used with this series (default: the first scale) */
  xScaleIdx?: XIdx;
  /** index of the y scale used with this series (default: the first scale) */
  yScaleIdx?: YIdx;
  /** the color associated with this series (default: taken from the theme) */
  color?: string;
}

/**
 * Series that accepts `any` as its Datum. This should usually be used to
 * constrain generics, e.g. `<T extends AnySeries>`.
 */
export type AnySeries = Series<any>;

export interface Series<
  Datum = object | string | number | boolean,
  K extends string = string,
  X = any,
  Y = any,
  Y0 = any,
  XIdx extends number = number,
  YIdx extends number = number,
> {
  /** an identifier for this series */
  seriesKey: K;
  /** a label for this series */
  label: string;
  /** the original data */
  data: Datum[];
  /** data filtered down to defined values (used for tooltips) */
  definedData: Datum[];
  /** a function used to compute the x value */
  x: Accessor<Datum, X>;
  /** a function used to compute the y value */
  y: Accessor<Datum, Y>;
  /** for bars or areas, a function to compute the bottom y value */
  y0: Accessor<Datum, Y0>;
  /** a function used to determine if a value is defined */
  defined: DefinedAccessor<Datum>;
  /** a function used to determine if a value is defined within the x domain */
  definedInXDomain: (d: Datum, xDomain: X[]) => boolean;
  /** a function used to determine if a value is defined within the y domain */
  definedInYDomain: (d: Datum, yDomain: Y[]) => boolean;
  /** a function used to find the Datum nearest to the given x value. */
  xNearest: (x: X) => Datum | undefined;
  /** a function used to find the Datum nearest to the given y value. */
  yNearest: (y: Y) => Datum | undefined;
  /** index of the x scale used with this series */
  xScaleIdx: XIdx;
  /** index of the y scale used with this series */
  yScaleIdx: YIdx;
  /** the color associated with this series */
  color?: string;
}

const makeAccessor = <Datum>(val: Accessor<Datum> | keyof Datum) =>
  typeof val === "function" ? val : (d: Datum) => d[val];

const makeDefinedAccessor = <Datum>(
  defined: Accessor<Datum, boolean> | keyof Datum | undefined,
  yAccessor: Accessor<Datum>,
) => {
  if (typeof defined === "function") {
    return defined;
  } else if (defined) {
    return (d: Datum) => d[defined] != null;
  } else {
    return (d: Datum) => yAccessor(d) != null;
  }
};

const makeSeries = <
  Datum,
  K extends string,
  XInput extends Accessor<Datum> | keyof Datum,
  YInput extends Accessor<Datum> | keyof Datum,
  Y0Input extends Accessor<Datum> | keyof Datum,
  XIdx extends number = 0,
  YIdx extends number = 0,
>({
  seriesKey,
  label,
  x,
  y,
  y0,
  defined,
  data,
  xScaleIdx,
  yScaleIdx,
  color,
}: SeriesInput<Datum, K, XInput, YInput, Y0Input, XIdx, YIdx>) => {
  type X = InferOutput<Datum, XInput>;
  type Y = InferOutput<Datum, YInput>;
  type Y0 = InferOutput<Datum, Y0Input, 0>;
  const xAccessor = makeAccessor(x);
  const yAccessor = makeAccessor(y);
  const definedAccessor = makeDefinedAccessor(defined, yAccessor);
  const bisectX = bisector(xAccessor).center;
  const bisectY = bisector(yAccessor).center;
  const definedData = data.filter(definedAccessor);
  const series: Series<Datum, K, X, Y, Y0, XIdx, YIdx> = {
    seriesKey,
    label: label ?? seriesKey,
    data,
    definedData,
    x: xAccessor,
    y: yAccessor,
    y0: y0 ? makeAccessor(y0) : () => 0,
    defined: definedAccessor,
    definedInXDomain: (d, domain) => {
      if (!definedAccessor(d)) {
        return false;
      }
      const xVal = xAccessor(d);
      return domain[0] <= xVal && xVal <= domain[domain.length - 1];
    },
    definedInYDomain: (d, domain) => {
      if (!definedAccessor(d)) {
        return false;
      }
      const yVal = yAccessor(d);
      return domain[0] <= yVal && yVal <= domain[domain.length - 1];
    },
    xNearest: (val: X) => definedData[bisectX(definedData, val)],
    yNearest: (val: Y) => definedData[bisectY(definedData, val)],
    xScaleIdx: xScaleIdx ?? (0 as XIdx),
    yScaleIdx: yScaleIdx ?? (0 as YIdx),
    color,
  };
  return series;
};

/**
 * Combines a set of data and its accessors. Returns a series to use in a
 * {@link Chart}.
 */
export const useSeries = <
  Datum,
  K extends string,
  XInput extends Accessor<Datum> | keyof Datum,
  YInput extends Accessor<Datum> | keyof Datum,
  Y0Input extends Accessor<Datum> | keyof Datum,
  XIdx extends number = 0,
  YIdx extends number = 0,
>({
  seriesKey,
  label,
  x,
  y,
  y0,
  defined,
  data,
  xScaleIdx,
  yScaleIdx,
  color,
}: SeriesInput<Datum, K, XInput, YInput, Y0Input, XIdx, YIdx>) =>
  useMemo(
    () =>
      makeSeries({
        seriesKey,
        label,
        x,
        y,
        y0,
        defined,
        data,
        xScaleIdx,
        yScaleIdx,
        color,
      }),
    [seriesKey, label, x, y, y0, defined, data, xScaleIdx, yScaleIdx, color],
  );

export interface SeriesTemplateInput<
  Datum,
  K extends string,
  XInput extends Accessor<Datum> | keyof Datum,
  YInput extends Accessor<Datum> | keyof Datum,
  Y0Input extends Accessor<Datum> | keyof Datum,
  XIdx extends number,
  YIdx extends number,
  Label extends Accessor<Datum> | keyof Datum | string[],
  Color extends Accessor<Datum> | keyof Datum | string[],
  GroupBy extends Accessor<Datum> | keyof Datum | undefined,
> {
  /** series keys will be this prefix plus an index */
  seriesKeyPrefix?: K;
  /** label template. may be a property, function, or a hard-coded array */
  label?: Label;
  /**
   * an array of input series data (array of arrays). with groupBy this is
   * a flat array of all the data.
   */
  data: undefined extends GroupBy ? Datum[][] : Datum[];
  /** with flat data, the property (or function) used to group data */
  groupBy?: GroupBy;
  /* the property (or function) used for x values */
  x: XInput;
  /* the property (or function) used for y values */
  y: YInput;
  /* the property (or function) used for the bottom y value (default: 0) */
  y0?: Y0Input;
  /**
   * the property (or function) used to determine if a value is defined
   * (defaults to the y value)
   */
  defined?: keyof Datum | DefinedAccessor<Datum>;
  /** index of the x scale used with this series (default: the first scale) */
  xScaleIdx?: XIdx;
  /** index of the y scale used with this series (default: the first scale) */
  yScaleIdx?: YIdx;
  /** color template. may be a property, function, or a hard-coded array */
  color?: Color;
  /** optional color scale function, using the `label` property. */
  colorScale?: (input: any) => string;
}

const templateValues = <Datum>(
  data: Datum[][],
  template?: Accessor<Datum> | keyof Datum | string[],
) => {
  if (Array.isArray(template)) {
    return template;
  }
  if (template) {
    const accessor = makeAccessor(template);
    return data.map((d) => (d.length > 0 ? accessor(d[0]) : null));
  }
  return null;
};

/**
 * Returns an array of series, given multiple input datasets and template
 * functions or properties for labels, colors, and x/y values.
 */
export const useSeriesTemplate = <
  Datum,
  XInput extends Accessor<Datum> | keyof Datum,
  YInput extends Accessor<Datum> | keyof Datum,
  Y0Input extends Accessor<Datum> | keyof Datum,
  XIdx extends number = 0,
  YIdx extends number = 0,
  K extends string = "series",
  Label extends Accessor<Datum> | keyof Datum | string[] = never,
  Color extends Accessor<Datum> | keyof Datum | string[] = never,
  GroupBy extends Accessor<Datum> | keyof Datum | undefined = undefined,
>({
  seriesKeyPrefix = "series" as K,
  label,
  x,
  y,
  y0,
  defined,
  data,
  xScaleIdx,
  yScaleIdx,
  color,
  groupBy,
  colorScale,
}: SeriesTemplateInput<
  Datum,
  K,
  XInput,
  YInput,
  Y0Input,
  XIdx,
  YIdx,
  Label,
  Color,
  GroupBy
>) =>
  useMemo(() => {
    const groupedData =
      groupBy === undefined
        ? (data as Datum[][])
        : [...group(data as Datum[], makeAccessor(groupBy)).values()];
    const labels = templateValues(groupedData, label);
    const colors = templateValues(groupedData, color);
    return groupedData.map((d, idx) =>
      makeSeries({
        seriesKey: `${seriesKeyPrefix}${idx}`,
        label: labels?.[idx],
        x,
        y,
        y0,
        defined,
        data: d,
        xScaleIdx,
        yScaleIdx,
        color: colorScale
          ? colorScale(colors?.[idx] ?? labels?.[idx] ?? idx)
          : colors?.[idx],
      }),
    );
  }, [
    seriesKeyPrefix,
    label,
    x,
    y,
    y0,
    defined,
    data,
    xScaleIdx,
    yScaleIdx,
    color,
    groupBy,
    colorScale,
  ]);
