import { identity } from "lodash";

import type { AnySeries, TooltipProps } from "src/bb-chart";
import useDeepCompareMemo from "src/hooks/useDeepCompareMemo";

import { ColorSquare } from "./ColorSquare";

export type Accessor<Datum> = keyof Datum | ((d: Datum) => React.ReactNode);

interface StdTooltipProps<S extends AnySeries[]> {
  className?: string;
  title?: Accessor<S[number]["data"][number]>;
  units?: React.ReactNode;
  fmtX?: (x: NonNullable<ReturnType<S[number]["x"]>>) => React.ReactNode;
  fmtY?: (y: NonNullable<ReturnType<S[number]["y"]>>) => React.ReactNode;
  zero?: React.ReactNode;
  nearestOnly?: boolean;
  showColor?: boolean;
}

const makeAccessor = <Datum,>(val: Accessor<Datum>) =>
  typeof val === "function" ? val : (d: Datum) => d[val];

const definedSeries = <D,>(series: Record<string, D>) =>
  Object.values(series).filter(Boolean) as NonNullable<D>[];

interface SeriesRowProps {
  label: React.ReactNode;
  color?: string;
  x: React.ReactNode;
  y: React.ReactNode;
  units?: React.ReactNode;
}

export const SeriesRow = ({ label, color, x, y, units }: SeriesRowProps) => (
  <>
    <div className="flex gap-2">
      {color && <ColorSquare className="flex-none" color={color} />}
      <span className="flex-grow w-72 max-w-fit truncate">{label}</span>
    </div>
    <div>
      {x}: <span className="font-bold">{y}</span> {units}
    </div>
  </>
);

export const stdTooltip = <S extends AnySeries[]>({
  className,
  title,
  fmtX = identity,
  fmtY = identity,
  zero,
  units,
  nearestOnly,
  showColor = true,
}: StdTooltipProps<S>) => {
  const titleAccessor = title ? makeAccessor(title) : null;
  const fmtUnits = typeof units === "function" ? units : () => units;
  type Props = TooltipProps<S[number]["data"][number]>;
  if (nearestOnly) {
    return ({ nearest: { label, color, x, y, datum } }: Props) => {
      const zeroOverride = y === 0 && zero != null;
      return (
        <div className={className}>
          {titleAccessor && (
            <div className="font-bold truncate">{titleAccessor(datum)}</div>
          )}
          <SeriesRow
            label={label}
            color={showColor ? color : undefined}
            x={fmtX(x)}
            y={zeroOverride ? zero : fmtY(y)}
            units={zeroOverride ? null : fmtUnits(y)}
          />
        </div>
      );
    };
  } else {
    return ({ series }: Props) => (
      <div className="flex flex-col gap-2">
        {definedSeries(series).map(({ seriesKey, label, color, x, y }) => {
          const zeroOverride = y === 0 && zero != null;
          return (
            <SeriesRow
              key={seriesKey}
              label={label}
              color={showColor ? color : undefined}
              x={fmtX(x)}
              y={zeroOverride ? zero : fmtY(y)}
              units={zeroOverride ? null : fmtUnits(y)}
            />
          );
        })}
      </div>
    );
  }
};

export const useStdTooltip = <S extends AnySeries[]>(
  props: StdTooltipProps<S>,
) => useDeepCompareMemo(() => stdTooltip(props), [props]);
