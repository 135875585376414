import { CloseOutlined } from "@ant-design/icons";
import { Button, Select, SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";
import cx from "classnames";
import { useMemo } from "react";

import { useOrdinalColorScale } from "src/bb-chart";

import { ColorSquare } from "./ColorSquare";

/* eslint-disable react/destructuring-assignment */

interface ChartLegendOwnProps<T extends string | number> {
  value?: T[];
  onChange?: (val: T[]) => void;
  options: DefaultOptionType[];
  colorScale?: (value: T) => string | undefined;
  placeholder?: React.ReactNode;
  /** Allow the chart legend to shrink while scrolling just the list. */
  flex?: boolean;
}

export type ChartLegendProps<T extends string | number> =
  ChartLegendOwnProps<T> & Omit<SelectProps, keyof ChartLegendOwnProps<T>>;

const ChartLegendInner = <T extends string | number>({
  placeholder,
  value,
  onChange,
  options,
  className,
  colorScale,
  flex,
  ...props
}: ChartLegendProps<T>) => {
  const getColor = (val: T) => colorScale?.(val);

  return (
    <div
      data-test="chart-legend"
      className={cx("flex flex-col gap-2", className, {
        "h-[7rem] overridable:flex-auto": flex,
      })}
    >
      <Select
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxTagCount={0}
        maxTagPlaceholder={() => (
          <span className="text-biobotblue-6">
            {value?.length ?? 0} Selected
          </span>
        )}
        dropdownMatchSelectWidth={false}
        mode="multiple"
        optionFilterProp="label"
        showSearch
        showArrow
        className="min-w-full"
        defaultActiveFirstOption={false}
        {...props}
      />
      <ul className="m-0 p-0 flex-auto min-h-0 overflow-auto">
        {value?.map((val) => (
          <li className="list-none" key={val}>
            <div className="flex justify-between items-center gap-2">
              <ColorSquare color={getColor(val)} />
              <div className="text-p2-thin truncate flex-1">{val}</div>
              <Button
                data-test="remove-series-button"
                type="ghost"
                size="small"
                title="Remove from graph"
                onClick={() => onChange?.(value.filter((v) => v !== val))}
                className="text-navy-7 p-0"
              >
                <CloseOutlined />
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ChartLegendCreateScale = <T extends string | number>(
  props: ChartLegendProps<T>,
) => {
  const flatOptions = (options: DefaultOptionType[]) =>
    options.flatMap((o) => o.options ?? [o]);

  const domain: (string | number)[] = useMemo(
    () => flatOptions(props.options).map((o) => o.value),
    [props.options],
  );
  const colorScale = useOrdinalColorScale(domain);
  return <ChartLegendInner {...props} colorScale={colorScale} />;
};

export const ChartLegend = <T extends string | number>(
  props: ChartLegendProps<T>,
) => {
  const Inner = props.colorScale ? ChartLegendInner : ChartLegendCreateScale;
  return <Inner {...props} />;
};

export default ChartLegend;
