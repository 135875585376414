import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import MainLayout from "src/components/MainLayout";
import CustomerProvider, {
  CustomerProviderReset,
} from "src/context/Customer/Provider";
import {
  FeatureFlagProvider,
  useFeatureFlagQuery,
} from "src/context/FeatureFlag";
import ThemeDemo from "src/demo/ThemeDemo";
import CreateCustomerPage from "src/pages/CustomerConfig/CreateCustomerPage";
import CustomerListPage from "src/pages/CustomerConfig/CustomerListPage";
import EditCustomerPage from "src/pages/CustomerConfig/EditCustomerPage";
import ViewCustomerPage from "src/pages/CustomerConfig/ViewCustomerPage";
import DownloadHubPage from "src/pages/DownloadHub/DownloadHubPage";
import FeatureFlagsPage from "src/pages/FeatureFlags/FeatureFlagsPage";
import Homepage from "src/pages/Homepage/Homepage";
import CovidPage from "src/pages/InfectiousDiseases/Covid/CovidPage";
import InfluenzaPage from "src/pages/InfectiousDiseases/Influenza/FluPage";
import NorovirusPage from "src/pages/InfectiousDiseases/Norovirus/NorovirusPage";
import RsvPage from "src/pages/InfectiousDiseases/RSV/RsvPage";
import IngestLogPage from "src/pages/IngestLog/IngestLogPage";
import NotFoundPage from "src/pages/NotFound/NotFound";
import NotificationBatchDetailPage from "src/pages/NotificationLog/NotificationBatchDetailPage";
import NotificationBatchListPage from "src/pages/NotificationLog/NotificationBatchListPage";
import NotificationDetailPage from "src/pages/NotificationLog/NotificationDetailPage";
import AcetylfentanylPage from "src/pages/Substances/AcetylfentanylPage";
import BuprenorphinePage from "src/pages/Substances/BuprenorphinePage";
import CarfentanilPage from "src/pages/Substances/CarfentanilPage";
import CocainePage from "src/pages/Substances/CocainePage";
import CodeinePage from "src/pages/Substances/CodeinePage";
import DihydrocodeinePage from "src/pages/Substances/DihydrocodeinePage";
import FentanylPage from "src/pages/Substances/FentanylPage";
import HeroinPage from "src/pages/Substances/HeroinPage";
import HydrocodonePage from "src/pages/Substances/HydrocodonePage";
import HydromorphonePage from "src/pages/Substances/HydromorphonePage";
import MethadonePage from "src/pages/Substances/MethadonePage";
import MethamphetaminePage from "src/pages/Substances/MethamphetaminePage";
import MorphinePage from "src/pages/Substances/MorphinePage";
import NaloxonePage from "src/pages/Substances/NaloxonePage";
import NicotinePage from "src/pages/Substances/NicotinePage";
import OxycodonePage from "src/pages/Substances/OxycodonePage";
import OxymorphonePage from "src/pages/Substances/OxymorphonePage";
import SufentanilPage from "src/pages/Substances/SufentanilPage";
import TianeptinePage from "src/pages/Substances/TianeptinePage";
import TramadolPage from "src/pages/Substances/TramadolPage";
import XylazinePage from "src/pages/Substances/XylazinePage";
import userSettingsRoutes from "src/pages/UserSettings/routes";
import { RoleName } from "src/util/hooks";

import FeatureProtectedRoute from "./FeatureProtectedRoute";
import RoleProtectedRoute from "./RoleProtectedRoute";
import { useGoogleAnalytics } from "./UseGoogleAnalytics";
import { ROUTES } from "./routeMap";

Sentry.init({
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const useAuth0SentryUser = () => {
  const { user } = useAuth0();
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.email,
        auth0Roles: user["https://api.biobot.io/roles"],
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const buildInternalProtectedRoute = (
  path: string,
  roleName: RoleName,
  element: React.ReactNode,
) => (
  <Route key={path} element={<RoleProtectedRoute roleName={roleName} />}>
    <Route path={path} element={element} />
  </Route>
);

const AppWrapper = withAuthenticationRequired(
  ({ adminPage }: { adminPage?: boolean }) => {
    // prefetch feature flags to speed up initialization (customer config is
    // already fetched immediately in CustomerProvider, so both of these
    // queries should happen simultaneously)
    useFeatureFlagQuery();
    return (
      <CustomerProvider>
        <FeatureFlagProvider>
          <MainLayout adminPage={adminPage}>
            {adminPage ? (
              <CustomerProviderReset>
                <Outlet />
              </CustomerProviderReset>
            ) : (
              <Outlet />
            )}
          </MainLayout>
        </FeatureFlagProvider>
      </CustomerProvider>
    );
  },
);

const redirects = {
  "/data-export": ROUTES.download_hub,
  "/substances/high-risk-substances/cocaine": ROUTES.cocaine,
  "/substances/high-risk-substances/fentanyl": ROUTES.fentanyl,
  "/substances/high-risk-substances/methamphetamine": ROUTES.methamphetamine,
  "/substances/high-risk-substances/naloxone": ROUTES.naloxone,
  "/substances/high-risk-substances/nicotine": ROUTES.nicotine,
  "/substances/high-risk-substances/xylazine": ROUTES.xylazine,
};

const DashboardRoutes = () => {
  const location = useLocation();
  useAuth0SentryUser();
  useGoogleAnalytics();

  return (
    <SentryRoutes location={location}>
      {/* Customer facing routes */}
      <Route element={<AppWrapper />}>
        <Route path="/" element={<Homepage />} />
        {userSettingsRoutes}
        <Route path={ROUTES.download_hub} element={<DownloadHubPage />} />
        <Route path={ROUTES.norovirus} element={<NorovirusPage />} />
        <Route path={ROUTES.covid} element={<CovidPage />} />
        <Route element={<FeatureProtectedRoute flag="fluRsvPagesUi" />}>
          <Route path={ROUTES.rsv} element={<RsvPage />} />
          <Route path={ROUTES.flu} element={<InfluenzaPage />} />
        </Route>
        <Route element={<FeatureProtectedRoute flag="substancesPagesUi" />}>
          {/* HRS */}
          <Route path={ROUTES.cocaine} element={<CocainePage />} />
          <Route path={ROUTES.fentanyl} element={<FentanylPage />} />
          <Route
            path={ROUTES.methamphetamine}
            element={<MethamphetaminePage />}
          />
          <Route path={ROUTES.nicotine} element={<NicotinePage />} />
          <Route path={ROUTES.xylazine} element={<XylazinePage />} />
          {/* SUD */}
          <Route element={<FeatureProtectedRoute flag="sudPanelUi" />}>
            <Route
              path={ROUTES.buprenorphine}
              element={<BuprenorphinePage />}
            />
            <Route path={ROUTES.methadone} element={<MethadonePage />} />
          </Route>
          <Route path={ROUTES.naloxone} element={<NaloxonePage />} />
          <Route element={<FeatureProtectedRoute flag="sudPanelUi" />}>
            {/* Illicit Opioids & Adjacent Substances */}
            <Route
              path={ROUTES.acetylfentanyl}
              element={<AcetylfentanylPage />}
            />
            <Route path={ROUTES.carfentanil} element={<CarfentanilPage />} />
            <Route path={ROUTES.heroin} element={<HeroinPage />} />
            <Route path={ROUTES.sufentanil} element={<SufentanilPage />} />
            <Route path={ROUTES.tianeptine} element={<TianeptinePage />} />
            {/* Rx Opioids */}
            <Route path={ROUTES.codeine} element={<CodeinePage />} />
            <Route
              path={ROUTES.dihydrocodeine}
              element={<DihydrocodeinePage />}
            />
            <Route path={ROUTES.hydrocodone} element={<HydrocodonePage />} />
            <Route
              path={ROUTES.hydromorphone}
              element={<HydromorphonePage />}
            />
            <Route path={ROUTES.morphine} element={<MorphinePage />} />
            <Route path={ROUTES.oxycodone} element={<OxycodonePage />} />
            <Route path={ROUTES.oxymorphone} element={<OxymorphonePage />} />
            <Route path={ROUTES.tramadol} element={<TramadolPage />} />
          </Route>
        </Route>
      </Route>
      {/* Internal pages */}
      <Route element={<AppWrapper adminPage />}>
        <Route
          path="/customers"
          element={<RoleProtectedRoute roleName="biobot-admin" />}
        >
          <Route path="" element={<CustomerListPage />} />
          <Route path="create" element={<CreateCustomerPage />} />
          <Route path=":customer_code" element={<ViewCustomerPage />} />
          <Route path=":customer_code/edit" element={<EditCustomerPage />} />
        </Route>
        <Route
          path="/notifications"
          element={<RoleProtectedRoute roleName="biobot-admin" />}
        >
          <Route path="" element={<NotificationBatchListPage />} />
          <Route path="batch/:key" element={<NotificationBatchDetailPage />} />
          <Route path=":key" element={<NotificationDetailPage />} />
        </Route>
        {buildInternalProtectedRoute(
          ROUTES.ingest_log,
          "biobot-admin",
          <IngestLogPage />,
        )}
        {buildInternalProtectedRoute(
          ROUTES.feature_flags,
          "biobot-feature-flagger",
          <FeatureFlagsPage />,
        )}
      </Route>
      {process.env.REACT_APP_DEMO_ROUTES === "true" && (
        <Route path="/demo/theme" element={<ThemeDemo />} />
      )}
      {Object.entries(redirects).map(([from, to]) => (
        <Route key={from} path={from} element={<Navigate to={to} replace />} />
      ))}
      <Route path="*" element={<NotFoundPage />} />
    </SentryRoutes>
  );
};

export default DashboardRoutes;
