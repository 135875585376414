import dayjs from "dayjs";

import api, { type ApiData } from "src/api";
import { useCustomer } from "src/context/Customer";

export type Dataset = keyof ReturnType<typeof datasetFilenames>;
export type Datasets = readonly Dataset[];
export type Links = Pick<ApiData<"export.links">, Dataset>;

const selectLinks = (x: { data: Links }) => x.data;

export const datasetFilenames = (customer_name: string) => {
  const prefix = customer_name
    .replace(/ /g, "_")
    .replace(/[^A-Za-z0-9_-]/g, "_")
    .replace(/__+/g, "_");
  const today = dayjs().format("YYYY-MM-DD");
  return {
    // covid
    covidCDC: `${prefix}_nwss_cdc_${today}.csv`,
    covidNWSS: `${prefix}_nwss_${today}.csv`,
    covidKits: `${prefix}_sars_cov_2_${today}.csv`,
    covidCountyAverage: `${prefix}_subscription_county_sars_cov_2_${today}.csv`,
    covidStateAverage: `${prefix}_subscription_state_sars_cov_2_${today}.csv`,
    covidRegionalAverage: `${prefix}_subscription_regional_sars_cov_2_${today}.csv`,
    covidNationalAverage: `${prefix}_subscription_national_sars_cov_2_${today}.csv`,
    covidCountyInterpolated: `${prefix}_ai_subscription_county_sars_cov_2_${today}.csv`,
    // flu
    fluKits: `${prefix}_influenza_${today}.csv`,
    fluCountyAverage: `${prefix}_subscription_county_influenza_${today}.csv`,
    fluStateAverage: `${prefix}_subscription_state_influenza_${today}.csv`,
    fluRegionalAverage: `${prefix}_subscription_regional_influenza_${today}.csv`,
    fluNationalAverage: `${prefix}_subscription_national_influenza_${today}.csv`,
    fluCountyInterpolated: `${prefix}_ai_subscription_county_influenza_${today}.csv`,
    // rsv
    rsvKits: `${prefix}_rsv_${today}.csv`,
    rsvCountyAverage: `${prefix}_subscription_county_rsv_${today}.csv`,
    rsvStateAverage: `${prefix}_subscription_state_rsv_${today}.csv`,
    rsvRegionalAverage: `${prefix}_subscription_regional_rsv_${today}.csv`,
    rsvNationalAverage: `${prefix}_subscription_national_rsv_${today}.csv`,
    rsvCountyInterpolated: `${prefix}_ai_subscription_county_rsv_${today}.csv`,
    // norovirus
    norovirusKits: `${prefix}_norovirus_${today}.csv`,
    norovirusCountyAverage: `${prefix}_subscription_county_norovirus_${today}.csv`,
    norovirusStateAverage: `${prefix}_subscription_state_norovirus_${today}.csv`,
    norovirusRegionalAverage: `${prefix}_subscription_regional_norovirus_${today}.csv`,
    norovirusNationalAverage: `${prefix}_subscription_national_norovirus_${today}.csv`,
    // mpox
    mpoxNWSS: `${prefix}_nwss_mpx_${today}.csv`,
    // hrs
    hrsKits: `${prefix}_hrs_${today}.csv`,
    lcmsNationalAverage: `${prefix}_subscription_national_lcms_${today}.csv`,
  };
};

// This should be less than the token expiration time
const linkTtlMs = 1 * 60 * 1000;

export const useDownloadHubLinks = () => {
  const { customer_name } = useCustomer();
  return api.export.links.useQuery(
    {
      filenames: datasetFilenames(customer_name),
    },
    {
      select: selectLinks,
      staleTime: linkTtlMs,
      cacheTime: linkTtlMs,
      refetchInterval: linkTtlMs,
    },
  );
};

export interface LinkComponentProps<T extends Datasets> {
  links?: Pick<Links, T[number]>;
  loading?: boolean;
}

interface UseLinkComponentProps<T extends Datasets>
  extends LinkComponentProps<T> {
  datasets: T;
}

export const useLinkComponent = <T extends Datasets>({
  links,
  loading,
  datasets,
}: UseLinkComponentProps<T>) => {
  if (loading) {
    return { state: "loading", links: null } as const;
  } else if (links && datasets.some((k) => links[k])) {
    return { state: "data", links } as const;
  } else {
    return { state: "no-data", links: null } as const;
  }
};
