import {
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Card, Dropdown, Form, FormInstance, Spin } from "antd";
import { omit, pick } from "lodash";
import { useState } from "react";

import CountyDataFilter from "./CountyDataFilter";
import DataFilterTags from "./DataFilterTags";
import KitDataFilter from "./KitDataFilter";
import NationalDataFilter from "./NationalDataFilter";
import RegionalDataFilter from "./RegionalDataFilter";
import StateDataFilter from "./StateDataFilter";
import type {
  DataFilterComponentMap,
  DataFilterOptions,
  DataFilterWithIsNew,
} from "./types";

interface DataFilterListProps {
  options: DataFilterOptions;
}

interface DataFilterListItemProps {
  name: number;
  remove: (name: number) => void;
  options: DataFilterOptions;
}

const filterComponents: DataFilterComponentMap = {
  kit: KitDataFilter,
  county: CountyDataFilter,
  state: StateDataFilter,
  regional: RegionalDataFilter,
  national: NationalDataFilter,
};

const dataFilterFields = (form: FormInstance, idx: number) => {
  const vals = form.getFieldsValue()?.data_filter?.[idx];
  return Object.keys(vals).map((k) => ["data_filter", idx, k]);
};

const tryValidate = async (form: FormInstance, idx: number) => {
  try {
    await form.validateFields(dataFilterFields(form, idx));
    return true;
  } catch (_) {
    return false;
  }
};

const DataFilterListItem = ({
  name,
  options,
  remove,
}: DataFilterListItemProps) => {
  const form = Form.useFormInstance();
  const dataFilterWatch = Form.useWatch("data_filter", form)?.[name];
  const dataFilterInitial = form.getFieldValue(["data_filter", name]);
  const dataFilter: DataFilterWithIsNew = dataFilterWatch ?? dataFilterInitial;
  const filterType = dataFilter?.filter_type;
  const [collapsed, setCollapsed] = useState(!dataFilter._isNew);
  const Component =
    filterComponents[filterType as keyof typeof filterComponents];
  if (!filterType) {
    // Waiting for a re-render to get the filterType
    return <Spin />;
  } else if (!Component) {
    throw new Error(`Unknown filter type: ${filterType}`);
  } else {
    const title = `${filterType} data`;
    return (
      <Card
        size="small"
        type="inner"
        title={
          <div className="flex items-center gap-4">
            <Button
              title={`${collapsed ? "collapse" : "expand"} ${title}`}
              type="ghost"
              className="m-0 p-0"
              onClick={async () => {
                setCollapsed(!collapsed && (await tryValidate(form, name)));
              }}
            >
              {collapsed ? <RightOutlined /> : <DownOutlined />}
              <span className="capitalize">{title}</span>
            </Button>
            <div className="flex w-0 min-w-full">
              <DataFilterTags
                dataFilter={pick(dataFilter, ["dataset_name"])}
                options={options}
                maxTags={4}
              />
            </div>
          </div>
        }
        extra={
          <Button
            title="Remove filter"
            type="ghost"
            className="grayscale m-0 p-0"
            onClick={() => remove(name)}
          >
            <CloseOutlined />
          </Button>
        }
      >
        {/* we need to keep the form items mounted for antd form validation */}
        <div className={collapsed ? "hidden" : ""}>
          <Component name={[name]} options={options} />
        </div>
        {collapsed && (
          <div className="flex flex-wrap gap-y-2 w-0 min-w-full text-sm">
            <DataFilterTags
              dataFilter={omit(dataFilter, ["dataset_name"])}
              options={options}
            />
          </div>
        )}
      </Card>
    );
  }
};

const dropdownItems = Object.keys(filterComponents).map((label) => ({
  label: `${label} data`,
  key: label as keyof DataFilterComponentMap,
}));

const AddDataSliceButton = ({
  onClick,
}: {
  onClick: (filterType: keyof DataFilterComponentMap) => void;
}) => {
  const buttonProps = {
    type: "link",
    className: "p-0",
  } as const;
  if (dropdownItems.length === 1) {
    return (
      <Button {...buttonProps} onClick={() => onClick(dropdownItems[0].key)}>
        <PlusOutlined />
        Add data
      </Button>
    );
  } else {
    return (
      <Dropdown
        menu={{
          items: dropdownItems,
          onClick: (e) => onClick(e.key as keyof DataFilterComponentMap),
        }}
      >
        <Button {...buttonProps}>
          <PlusOutlined />
          Add data
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  }
};

const DataFilterList = ({ options }: DataFilterListProps) => (
  <Form.List name="data_filter">
    {(fields, { add, remove }, { errors }) => (
      <div className="space-y-2">
        {fields.map((field) => (
          <DataFilterListItem {...field} options={options} remove={remove} />
        ))}
        <Form.Item>
          <Form.ErrorList errors={errors} />
          <AddDataSliceButton
            onClick={(key) =>
              add({ ...filterComponents[key].defaultFilter, _isNew: true })
            }
          />
        </Form.Item>
      </div>
    )}
  </Form.List>
);

export default DataFilterList;
