import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";
import { renameAvgColumns } from "./LCMSCommandCenter/util";

export const compounds = [
  { label: "Xylazine", value: "Xylazine" },
  { label: "4-hydroxyxylazine", value: "4-hydroxy xylazine" },
];

const aboutTheData = (
  <p>
    Xylazine is a veterinary tranquilizer that has emerged as a contaminant in
    illicit opioid supplies in recent years. The CDC, DEA, and several other
    federal, state, and local agencies have issued warnings about the increased
    risk to communities related to the presence of this substance in the illicit
    drug supply. Because xylazine is not an opioid, naloxone (Narcan) does not
    reverse its effects.
  </p>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const XylazinePage = () => {
  const { data, isLoading } = api.data.xylazineWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  const { data: avgData, isLoading: avgIsLoading } =
    api.data.xylazineNational.useQuery(
      { _fields: ["target_name", "date", "estimated_consumption_weekly_avg"] },
      { select: renameAvgColumns },
    );

  const combinedData = [...(avgData?.data ?? []), ...(data?.data ?? [])];

  return (
    <LCMSCommandCenter
      data={combinedData}
      isLoading={isLoading || avgIsLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default XylazinePage;
