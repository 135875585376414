import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [
  { label: "Sufentanil", value: "Sufentanil" },
  { label: "Norsufentanil", value: "Norsufentanil" },
];

const aboutTheData = (
  <>
    <p>
      Sufentanil is a fentanyl analog and is a more potent synthetic opioid than
      fentanyl. In a clinical setting, it is used primarily in anesthesia and
      for severe pain management. It is typically administered intravenously or
      via a sublingual tablet. Sufentanil has a very high potential for misuse
      and overdose, even at small doses. Sufentanil and its metabolite
      norsufentanil are excreted in urine but degrade quickly in wastewater.
      Upstream sampling locations are therefore recommended for monitoring
      sufentanil use.
    </p>
    <p>
      Medical doses are very small, typically ranging from 0.005 mg to 0.1 mg,
      due to its high potency.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const SufentanilPage = () => {
  const { data, isLoading } = api.data.sufentanilWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default SufentanilPage;
