import type { Accessor } from "./Series";

interface BarPathParams<Datum> {
  /** Accessor for the center of the bar */
  x: Accessor<Datum, number>;
  /** Accessor for the top of the bar */
  y: Accessor<Datum, number>;
  /** Accessor for the bottom of the bar */
  y0: Accessor<Datum, number>;
  /** Defined accessor */
  defined?: Accessor<Datum, boolean>;
  /** Width of each bar in px */
  width: number;
}

/** Creates a single path representing a series of bars. */
export const barPath =
  <Datum>({ x, y, y0, defined, width }: BarPathParams<Datum>) =>
  (data: Datum[]) =>
    data
      .map((d) => {
        if (!defined || defined(d)) {
          const midX = x(d);
          const top = y(d);
          const bottom = y0(d);
          return `
            M ${midX - width / 2} ${bottom}
            V ${top}
            H ${midX + width / 2}
            V ${bottom}
            H ${midX - width / 2}
          `;
        } else {
          return "";
        }
      })
      .join(" ");
