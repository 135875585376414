import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

export const compounds = [
  { label: "Oxycodone", value: "Oxycodone" },
  { label: "Noroxycodone", value: "Noroxycodone" },
];

const aboutTheData = (
  <>
    <p>
      Oxycodone is a prescription opioid used to treat moderate to severe pain
      and is available in several forms, including tablets, capsules, and
      liquid, designed to be taken orally. It is often prescribed in
      controlled-release formulations for long-lasting effect. Oxycodone is
      highly addictive and has high potential for misuse and dependence.
      Oxycodone and its major metabolite, noroxycodone, are excreted in urine
      and are moderately stable in wastewater.
    </p>
    <p>
      Prescription doses vary, but are typically between 5 mg and 20 mg for
      standard formulations and between 5 mg and 120 mg for controlled-release
      formulations, depending on the patient’s pain management needs and
      tolerance. Recreational doses, however, may exceed these amounts.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const OxycodonePage = () => {
  const { data, isLoading } = api.data.oxycodoneWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default OxycodonePage;
