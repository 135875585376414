import { Table, TableColumnsType, Tag } from "antd";
import Link from "antd/es/typography/Link";
import { concat, filter, startCase } from "lodash";
import React from "react";

import api from "src/api";
import { useTableQueryParams } from "src/hooks/useTableQueryParams";

import WatchlistMethods from "./WatchlistMethods";

const tagMapping = {
  Stable: { tagColor: "gold", tagText: "Stable" },
  Increasing: { tagColor: "red", tagText: "Increasing" },
  Decreasing: { tagColor: "green", tagText: "Decreasing" },
  Unknown: { tagColor: "default", tagText: "Insufficient Data" },
};

const BuildingsOnlyDescription = () => (
  <>
    Our{" "}
    <span className="text-biobotblue-5 break-keep">SARS&#8209;CoV&#8209;2</span>{" "}
    building insights feature is still in R&D, if you’d like to be on the
    waitlist for this please contact us at{" "}
    <Link
      className="text-h3 underline"
      href="mailto:support@biobot.io?subject=Unlock Covid Building Trends Data"
      target="_blank"
    >
      support@biobot.io
    </Link>
    .
  </>
);

const DataDescription = ({ assayText }: { assayText: React.ReactNode }) => (
  <>
    Explore the dynamically updated table below to understand the latest trends
    in
    <span className="text-biobotblue-5 break-keep"> {assayText} </span>{" "}
    infection rates across various locations.
  </>
);

const TrendsTable = ({
  assay,
}: {
  assay: {
    text: React.ReactNode;
    value: string;
  };
}) => {
  const dataQuery = api[assay.value].wastewater.trends;

  type TrendsRow = (typeof dataQuery._output.data)[number];

  const { queryParams, handleTableChange, userSelectedFilters } =
    useTableQueryParams<TrendsRow>({
      permanentFilters: {
        trend: undefined, // don't send the selected trends to the backend call
      },
    });

  const { data: tableData, isLoading } = dataQuery.useQuery({
    ...queryParams,
    _limit: -1,
  });

  const communityOnlyTrends = filter(
    tableData?.data,
    (trendDatum) =>
      trendDatum.location_type === "community" &&
      (!userSelectedFilters?.trend?.length ||
        userSelectedFilters?.trend?.includes(trendDatum.trend)),
  );

  const buildingOnlyTrends = filter(
    tableData?.data,
    (trendDatum) =>
      trendDatum.location_type === "facility" &&
      !userSelectedFilters?.trend?.length, // never show building locations when filtering trends
  );

  const trendsSortedByLocation = concat(
    communityOnlyTrends,
    buildingOnlyTrends,
  );

  type Row = NonNullable<typeof tableData>["data"][number];

  const columns: TableColumnsType<Row> = [
    {
      title: "Name",
      dataIndex: "sampling_location_name",
      key: "sampling_location_id",
      filters: tableData?.data.map((d) => ({
        text: d.sampling_location_name,
        value: d.sampling_location_id,
      })),
      filterMode: "menu",
      filterMultiple: true,
      filterSearch: true,
    },
    {
      title: "Location-Type",
      dataIndex: "location_type",
      key: "location_type",
      render: (type?: string) =>
        startCase(type === "facility" ? "Building" : type),
      filters: [
        {
          text: "Building",
          value: "facility",
        },
        {
          text: "Community",
          value: "community",
        },
      ],
    },
    {
      title: "Beta Trends",
      dataIndex: "",
      key: "trend",
      render: (trendDatum: typeof dataQuery._output.data) => (
        <div>
          {trendDatum.location_type === "facility" ? (
            "Feature in R&D"
          ) : (
            <Tag
              color={tagMapping?.[trendDatum.trend].tagColor}
              className="text-p2 font-light"
            >
              {tagMapping[trendDatum.trend]?.tagText}
            </Tag>
          )}
        </div>
      ),
      filters: [
        { text: "Increasing", value: "Increasing" },
        { text: "Decreasing", value: "Decreasing" },
        { text: "Stable", value: "Stable" },
        { text: "Insufficient Data ", value: "Unknown" },
      ],
    },
  ];

  return (
    <div>
      {communityOnlyTrends.length >= 1 ? (
        <DataDescription assayText={assay.text} />
      ) : (
        <BuildingsOnlyDescription />
      )}
      <Table
        rowKey="sampling_location_id"
        scroll={{ x: "min-content" }}
        loading={isLoading}
        columns={columns}
        pagination={false}
        dataSource={trendsSortedByLocation}
        onChange={handleTableChange}
      />
      <div className="mt-5 mb-1">
        <WatchlistMethods />
      </div>
    </div>
  );
};

export default TrendsTable;
