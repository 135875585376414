import { Alert, Table, TableColumnsType, Tag } from "antd";
import { Link } from "react-router-dom";

import api, { type ApiData } from "src/api";
import {
  filterEqual,
  filterOptions,
  multiSorter,
  numberSorter,
  renderTimestampLocal,
  stringSorter,
} from "src/util/table";

interface NotificationListProps {
  filter?: {
    notification_batch_key?: number;
    customer_code?: string;
  };
}

const stateTagColors = {
  sent: "green",
  failed: "red",
  scheduled: "blue",
  sending: "purple",
};

type Notification = ApiData<"notification.list">[number];

export const renderCustomerLink = (notification: Notification) => (
  <Link to={`/customers/${notification.customer_code}`}>
    {notification.customer_name}
  </Link>
);

export const renderNotificationBatchLink = (notification: Notification) => (
  <Link to={`/notifications/batch/${notification.notification_batch_key}`}>
    {notification.notification_type} ({notification.notification_batch_key})
  </Link>
);

export const renderNotificationState = (state: Notification["state"]) => (
  <Tag className="text-sm" color={stateTagColors[state]}>
    {state}
  </Tag>
);

const NotificationList = ({ filter }: NotificationListProps) => {
  const {
    data: notificationList,
    isLoading,
    isError,
  } = api.notification.list.useQuery({ _where: filter });

  if (isError) return <Alert message="Error loading notifications" />;

  const tableData = notificationList?.data ?? [];

  const columns: TableColumnsType<Notification> = [
    {
      title: "Recipient",
      dataIndex: [],
      key: "recipient",
      filters: filterOptions(tableData, "recipient"),
      filterSearch: true,
      onFilter: filterEqual("recipient"),
      sorter: stringSorter("recipient"),
      render: (d: Notification) => (
        <Link to={`/notifications/${d.key}`}>{d.recipient}</Link>
      ),
    },
    {
      title: "State",
      dataIndex: "state",
      filters: filterOptions(tableData, "state"),
      onFilter: filterEqual("state"),
      sorter: stringSorter("state"),
      render: renderNotificationState,
    },
    {
      title: "Sent",
      dataIndex: "sent_at",
      key: "sent_at",
      sorter: stringSorter("sent_at"),
      defaultSortOrder: "descend",
      render: renderTimestampLocal,
    },
    {
      title: "Scheduled",
      dataIndex: "send_after",
      key: "send_after",
      sorter: stringSorter("send_after"),
      render: renderTimestampLocal,
    },
    {
      title: "Notification Batch",
      key: "notification_batch_key",
      filters: filterOptions(
        tableData,
        "notification_batch_key",
        "notification_type",
      ),
      filterSearch: true,
      onFilter: filterEqual("notification_batch_key"),
      sorter: multiSorter(
        stringSorter("notification_type"),
        numberSorter("notification_batch_key"),
      ),
      render: renderNotificationBatchLink,
    },
    {
      title: "Customer",
      key: "customer_code",
      filters: filterOptions(tableData, "customer_code", "customer_name"),
      filterSearch: true,
      onFilter: filterEqual("customer_code"),
      sorter: stringSorter("customer_name"),
      render: renderCustomerLink,
    },
  ];

  return (
    <div className="space-y-4">
      <p className="italic">Timestamps are displayed in your time zone.</p>
      <Table
        rowKey="key"
        scroll={{ x: "max-content" }}
        loading={isLoading}
        columns={columns}
        pagination={{
          defaultPageSize: 10,
        }}
        dataSource={tableData}
      />
    </div>
  );
};

export default NotificationList;
