import { Route } from "react-router-dom";

import RoleProtectedRoute from "src/routes/RoleProtectedRoute";

import ApiTokenCreatePage from "./ApiTokenCreatePage";
import ApiTokenListPage from "./ApiTokenListPage";
import UserSettingsPage from "./UserSettingsPage";

export default (
  <Route
    path="/settings"
    element={
      <RoleProtectedRoute roleName={["biobot-admin", "api-token-user"]} />
    }
  >
    <Route path="" element={<UserSettingsPage />} />
    <Route path="tokens" element={<ApiTokenListPage />} />
    <Route path="tokens/new" element={<ApiTokenCreatePage />} />
  </Route>
);
