import mpoxImg from "../../images/mpx_virus.png";
import AssayCard from "./AssayCard";
import { ExportButton } from "./Buttons";
import { Dataset, LinkComponentProps, useLinkComponent } from "./links";

const datasets = ["mpoxNWSS"] satisfies Dataset[];

interface MpoxDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const MpoxDownloads = ({ className, ...props }: MpoxDownloadsProps) => {
  const { state, links } = useLinkComponent({ ...props, datasets });
  return (
    <div className={className}>
      <AssayCard
        disabled={state === "no-data" || state === "loading"}
        title="Mpox"
        image={mpoxImg}
        className="mb-5"
      />
      {state === "loading" && <ExportButton label="loading..." loading />}
      {state === "data" && (
        <ExportButton
          label="NWSS+"
          href={links.mpoxNWSS}
          gaAction="mpoxNWSS-downloaded"
        />
      )}
    </div>
  );
};

export default MpoxDownloads;
