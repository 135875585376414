import { localPoint } from "@visx/event";
import type { AnyD3Scale } from "@visx/scale";
import { every, isNil, map, mapValues, maxBy, minBy, omitBy } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import { useChartData } from "./ChartData";
import { useChartSize } from "./ChartSize";
import HoverDetector, { HoverHandler } from "./HoverDetector";
import { AnySeries, Series } from "./Series";
import { invertScale } from "./util";

// -- Contexts --

export interface TooltipSeries<T extends AnySeries = AnySeries> {
  seriesKey: T["seriesKey"];
  label: string;
  color?: T["color"];
  // we're guaranteeing that the data is defined at this point, so it should
  // not have null x or y values
  x: NonNullable<ReturnType<T["x"]>>;
  y: NonNullable<ReturnType<T["y"]>>;
  screenX: number;
  screenY: number;
  datum: T["definedData"][number];
}

export interface TooltipData<TSeries extends AnySeries[]> {
  /** The cursor position */
  cursor: { x: number; y: number };
  /** The closest point to the cursor (by x value) */
  nearest: TooltipSeries<TSeries[number]>;
  /**
   * The closest value for each series (may be undefined if the point is
   * outside of the hover tolerance)
   */
  series: {
    [I in 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 as TSeries[I]["seriesKey"]]?: {
      seriesKey: string;
    } & TooltipSeries<TSeries[I]>;
  };
}

interface TooltipState<TSeries extends AnySeries[] = Series[]> {
  top?: number;
  left?: number;
  data?: TooltipData<TSeries>;
  isOpen: boolean;
}

const TooltipContext = createContext<TooltipState>({
  isOpen: false,
});

type TooltipSetter = (state: TooltipState) => void;

const TooltipSetterContext = createContext<TooltipSetter | undefined>(
  undefined,
);

export const useTooltip = () => useContext(TooltipContext);

const useTooltipSetter = () => {
  const value = useContext(TooltipSetterContext);
  if (!value) {
    throw new Error("useTooltipSetter must be used in TooltipProvider");
  }
  return value;
};

// -- Providers --

// The already-inverted x, y point for the primary axes.
interface TooltipPoint {
  x0?: number | Date;
  y0?: number | Date;
}

const nearestPoint = ({
  series,
  xScale,
  yScale,
  x,
  tolerancePx,
}: {
  series: Series;
  xScale: AnyD3Scale;
  yScale: AnyD3Scale;
  x: number;
  y: number;
  tolerancePx: number;
}) => {
  const seriesX = invertScale(xScale, x);
  if (seriesX == null) {
    return undefined;
  }
  const nearestDatum = series.xNearest(seriesX);
  if (nearestDatum == null) {
    return undefined;
  }
  const datumX = series.x(nearestDatum);
  const datumY = series.y(nearestDatum);
  // convert to screen coords, centering if it's a band scale (e.g. bars)
  const width = "bandwidth" in xScale ? xScale.bandwidth() : 0;
  const height = "bandwidth" in yScale ? yScale.bandwidth() : 0;
  const screenX = xScale(datumX) + width / 2;
  const screenY = yScale(datumY) + height / 2;
  // Make sure this point is within the scale's domain, and not too far away.
  if (
    series.definedInXDomain(nearestDatum, xScale.domain()) &&
    Math.abs(x - screenX) <= tolerancePx
  ) {
    return {
      seriesKey: series.seriesKey,
      label: series.label,
      color: series.color,
      datum: nearestDatum,
      x: datumX,
      y: datumY,
      screenX,
      screenY,
    };
  } else {
    return undefined;
  }
};

const useTooltipCallbacks = ({ tolerance }: { tolerance: number }) => {
  const setTooltip = useTooltipSetter();
  const { margin, width } = useChartSize();
  const { xScales, yScales, series } = useChartData();
  const tolerancePx = Math.round(tolerance * width);

  const updateTooltip = useCallback(
    (point?: TooltipPoint) => {
      if (point?.x0 == null || point?.y0 == null) {
        setTooltip({ isOpen: false });
        return;
      }
      // Convert x0 and y0 to cursor positions
      const x = xScales[0](point.x0);
      const y = yScales[0](point.y0);
      // Find the closest data point to the cursor for each series
      const seriesData = mapValues(series, (s) =>
        nearestPoint({
          series: s,
          xScale: xScales[s.xScaleIdx],
          yScale: yScales[s.yScaleIdx],
          x,
          y,
          tolerancePx,
        }),
      );
      if (every(seriesData, isNil) || x == null || y == null) {
        // Close the tooltip if we are hovering over the chart but are nowhere
        // near any data points.
        setTooltip({ isOpen: false });
      } else {
        const nearest = minBy(Object.values(seriesData), (p) =>
          p ? Math.hypot(x - p.screenX, y - p.screenY) : Number.MAX_VALUE,
        );
        setTooltip({
          isOpen: true,
          data: {
            cursor: { x, y },
            nearest: nearest!,
            series: omitBy(seriesData, isNil),
          },
          left: x,
          top: y,
        });
      }
    },
    [series, xScales, yScales, setTooltip, tolerancePx],
  );

  const onHover = useCallback<HoverHandler>(
    (event) => {
      const cursor = localPoint(event);
      if (cursor) {
        const x0 = invertScale(xScales[0], cursor.x - margin.left);
        const y0 = invertScale(yScales[0], cursor.y - margin.top);
        updateTooltip({ x0, y0 });
      } else {
        updateTooltip(undefined);
      }
    },
    [xScales, yScales, margin.left, margin.top, updateTooltip],
  );

  const onLeave = useCallback<HoverHandler>(() => {
    updateTooltip(undefined);
  }, [updateTooltip]);

  return {
    onHover,
    onLeave,
  };
};

export interface TooltipProviderProps {
  children: React.ReactNode;
}

/**
 * Tooltip state provider. This is basically visx's useTooltip, but as context
 * so that it can be used from anywhere in the chart.
 */
export const TooltipProvider = ({ children }: TooltipProviderProps) => {
  const [state, setState] = useState<TooltipState>({ isOpen: false });
  return (
    <TooltipSetterContext.Provider value={setState}>
      <TooltipContext.Provider value={state}>
        {children}
      </TooltipContext.Provider>
    </TooltipSetterContext.Provider>
  );
};

/** An svg `rect` that captures cursor events and updates TooltipContext. */
export const TooltipHoverDetector = ({
  width,
  height,
  tolerance = 0.2, // 20% of the chart width
}: TooltipHoverDetectorProps) => {
  const { onHover, onLeave } = useTooltipCallbacks({ tolerance });
  return (
    <HoverDetector
      width={width}
      height={height}
      onHover={onHover}
      onLeave={onLeave}
    />
  );
};

// -- Display components --

/**
 * Renders hover elements (e.g. crosshairs, circles highlighting the hovered
 * points) when there is a tooltip present.
 *
 * Note that the actual elements shown on hover are defined separately as svg
 * `<defs>` elements (e.g. HoverVerticalLine, or most of the series include a
 * HoverPoint). This component includes several svg `<use>` elements to
 * display those elements on hover.
 *
 * There are a few reasons for the defs/use separation:
 * - It's nice to be able to write the hover component as part of the series
 *   component since they're both dealing with data display and styling.
 * - It's nice to be able to declare other hover elements within the Chart
 *   body directly without having to worry about the hover logic itself.
 * - Hover elements need to be rendered above everything else, or you risk
 *   having a series overlap the hover point (i.e. this rendering order does
 *   not work: line1, hover1, line2, hover2, instead you need line1, line2,
 *   hover1, hover2). Since there's no way to adjust z-index in svg, the
 *   defs/use setup makes sure that all these overlay elements are actually
 *   drawn on top of everything else, regardless of what react component
 *   renders them.
 */
export const TooltipHover = () => {
  const { isOpen, data } = useTooltip();
  const { idPrefix } = useChartData();
  if (!isOpen || !data) {
    return null;
  }
  return (
    <>
      <use href={`#${idPrefix}-hover-vertical`} x={data.nearest.screenX} />
      <use href={`#${idPrefix}-hover-horizontal`} y={data.nearest.screenY} />
      {map(
        data.series,
        (point, seriesKey) =>
          point && (
            <use
              key={seriesKey}
              href={`#${idPrefix}-hover-series-${seriesKey}`}
              x={point.screenX}
              y={point.screenY}
            />
          ),
      )}
      <use href={`#${idPrefix}-hover-custom`} />
    </>
  );
};

export interface TooltipHoverDetectorProps {
  width: number;
  height: number;
  tolerance?: number;
}

export type TooltipPropsForSeries<TSeries extends AnySeries[]> = TooltipData<
  [...TSeries]
>;

/**
 * Type for a Component that renders tooltips. The generic arg can either be a
 * tuple/array of Series, or the shape of the data that each series must use.
 *
 * You get more specific types if you use the Series[] version (internally, the
 * library uses this version), but it is much easier to use the Datum version
 * in user code.
 */
export type TooltipComponentForSeries<TSeries extends AnySeries[]> = (
  props: TooltipPropsForSeries<TSeries>,
) => JSX.Element | null;

interface TooltipWrapperProps<TSeries extends AnySeries[]> {
  tooltip: TooltipComponentForSeries<TSeries>;
  className?: string;
}

const bestLayout = ({
  x,
  y,
  width,
  height,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  const xright = { x: 0, justify: "justify-start" };
  const xcenter = { x: -width / 2, justify: "justify-center" };
  const xleft = { x: -width, justify: "justify-end" };
  const ybottom = { y: 0 };
  const ycenter = { y: -height / 2 };
  const ytop = { y: -height };
  const layouts = [
    { ...xright, ...ycenter },
    { ...xleft, ...ycenter },
    { ...xright, ...ybottom },
    { ...xleft, ...ybottom },
    { ...xcenter, ...ybottom },
    { ...xright, ...ytop },
    { ...xleft, ...ytop },
    { ...xcenter, ...ytop },
  ];
  const layoutSpace = (offset: (typeof layouts)[number]) => {
    const top = y + offset.y;
    const bottom = top + height;
    const left = x + offset.x;
    const right = left + width;
    const ySpace = Math.min(top, window.innerHeight - bottom);
    const xSpace = Math.min(left, window.innerWidth - right);
    return Math.min(xSpace, ySpace);
  };
  const goodLayout = layouts.find((val) => layoutSpace(val) > 10);
  if (goodLayout) {
    return goodLayout;
  }
  // We didn't find a layout that fits, so just return the least bad layout
  return maxBy(layouts, layoutSpace);
};

// Manually calculate the tooltip position. This is basically all of the logic
// that visx tooltip would do, but there's a show-stopping bug with
// TooltipInPortal and react-use-measure where elements that change position
// without a scroll or window resize do not update the tooltip position! (e.g.
// expanding a plot). See this comment from a visx maintainer on a bug thread:
// https://github.com/pmndrs/react-use-measure/issues/9#issuecomment-755817379
// The positioning logic isn't super complicated anyways, and this also lets us
// do fancier things, like vertically centering the tooltip on the cursor, plus
// we're able to get rid of the visx/tooltip dependency entirely.
const useTooltipPosition = () => {
  const { margin } = useChartSize();
  const { isOpen, top: tooltipTop, left: tooltipLeft } = useTooltip();
  const ref = useRef<HTMLDivElement>(null);
  if (!isOpen) {
    return { ref };
  }
  const x = (tooltipLeft ?? 0) + margin.left;
  const y = (tooltipTop ?? 0) + margin.top;
  if (!ref.current) {
    return { ref, top: y, left: x };
  }
  const { width, height } = ref.current.getBoundingClientRect();
  const parentRect = ref.current.parentElement?.getBoundingClientRect();
  const parentX = parentRect?.x ?? 0;
  const parentY = parentRect?.y ?? 0;
  const layout = bestLayout({ x: x + parentX, y: y + parentY, width, height });
  return {
    ref,
    top: y + (layout?.y ?? 0),
    left: x + (layout?.x ?? 0),
    justify: layout?.justify ?? "justify-start",
  };
};

/**
 * Wrapper component that displays the actual tooltip when it is open. You must
 * use visx's TooltipInPortal with this component.
 *
 * @example
 * const MyChart = () => {
 *   const { TooltipInPortal, containerRef } = useTooltipInPortal();
 *   return (
 *     <svg ref={containerRef}>
 *       ...
 *       <TooltipWrapper
 *         TooltipInPortal={TooltipInPortal}
 *         tooltip={(tooltipData) => <div>Hello world!</div>}
 *       />
 *     </svg>
 *   )
 * }
 */
export const TooltipWrapper = <TSeries extends AnySeries[]>({
  tooltip: TooltipComponent,
  className,
}: TooltipWrapperProps<TSeries>) => {
  const { isOpen, data } = useTooltip();
  const { ref, top, left, justify } = useTooltipPosition();
  if (!isOpen || !data) {
    return null;
  }
  return (
    <div
      ref={ref}
      style={{
        top,
        left,
        zIndex: 9999,
        pointerEvents: "none",
        padding: 5,
      }}
      // render invisibly one time until we can get an initial measurement
      className={ref.current ? "absolute" : "invisible fixed top-0 left-0"}
    >
      <div
        className={`flex max-w-screen w-max min-w-[50vw] md:min-w-[30vw] md:max-w-[50vw] ${justify}`}
      >
        <div className={className}>
          <TooltipComponent {...data} />
        </div>
      </div>
    </div>
  );
};
