import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";
import { summaryCardSubtitle } from "./LCMSCommandCenter/text";
import { renameAvgColumns } from "./LCMSCommandCenter/util";

export const compounds = [
  { label: "Nicotine", value: "Nicotine" },
  {
    label: "trans-3′-Hydroxycotinine",
    value: "trans-3’-Hydroxycotinine",
    defaultSelected: true,
  },
];

const summarySubtitle = (
  <div className="space-y-4">
    <p>{summaryCardSubtitle(compounds)}</p>
    <p className="text-p2-thin">
      Note: In a November 2023 methods update, Biobot stopped collecting data on
      the nicotine parent drug. See more details in the “about the data”
      dropdown.
    </p>
  </div>
);

const aboutTheData = (
  <>
    <p>
      Nicotine is a commonly used legal stimulant found in tobacco and
      electronic vapor products (i.e. vapes or e-cigarettes). Depending on the
      product, nicotine can be smoked, absorbed through the mouth and gums,
      administered via a skin patch, or vaporized and inhaled. Nicotine and its
      major metabolite, trans-3′-hydroxycotinine, are both excreted in urine and
      are stable in wastewater.
    </p>
    <p>
      Doses of nicotine can vary widely by product, but a common dose (i.e., one
      cigarette) can range between 1 mg and 4 mg. A lethal dose of nicotine is
      500 mg, though nicotine poisoning events are exceedingly rare and are
      usually related to the accidental ingestion of liquid nicotine.
    </p>
    <p>
      Note: In a November 2023 methods update, Biobot stopped collecting data on
      the nicotine parent drug. Estimates of unmetabolized nicotine in
      wastewater are often biased by rainfall and storm conditions, particularly
      in combined sewer systems where discarded cigarettes can be washed down
      storm drains and enter the wastewater. Trans-3′-hydroxycotinine therefore
      represents a more accurate estimate of nicotine consumption.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: {
    subtitle: summarySubtitle,
    aboutTheData,
  },
};

const NicotinePage = () => {
  const { data, isLoading } = api.data.nicotineWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  const { data: avgData, isLoading: avgIsLoading } =
    api.data.nicotineNational.useQuery(
      { _fields: ["target_name", "date", "estimated_consumption_weekly_avg"] },
      { select: renameAvgColumns },
    );

  const combinedData = [...(avgData?.data ?? []), ...(data?.data ?? [])];

  return (
    <LCMSCommandCenter
      data={combinedData}
      isLoading={isLoading || avgIsLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default NicotinePage;
