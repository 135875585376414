import {
  clone,
  flatMap,
  flow,
  identity,
  isUndefined,
  map,
  omitBy,
  overEvery,
} from "lodash";
import { useMemo } from "react";

import { UnitName as _UnitName, unitConverter } from "src/util/units";

import useDeepCompareMemo from "./useDeepCompareMemo";

export type UnitName = _UnitName; // re-export for convenience

/* eslint-disable @typescript-eslint/no-explicit-any */

const filterFns = {
  between: <T>(x: T, [min, max]: readonly [T | null, T | null]) =>
    (min == null || min <= x) && (max == null || x <= max),
  gt: <T>(a: T, b: T) => a > b,
  gte: <T>(a: T, b: T) => a >= b,
  lt: <T>(a: T, b: T) => a < b,
  lte: <T>(a: T, b: T) => a <= b,
  eq: <T>(a: T, b: T) => a === b,
  ne: <T>(a: T, b: T) => a !== b,
  in: <T>(a: T, bs: readonly T[]) => bs.includes(a),
} as const;

type MaybeRecord<Keys extends string, Vals> = {
  [K in Keys]?: Vals | null;
};

type Filter<T> =
  | MaybeRecord<"between", readonly [T | null, T | null]>
  | MaybeRecord<"in", readonly T[]>
  | MaybeRecord<"gt" | "gte" | "lt" | "lte" | "eq" | "ne", T>;

type FilterRecord<T> = {
  [K in keyof T]?: Filter<T[K]>;
};

const isEmptyFilter = (x: Filter<any> | undefined) =>
  x == null || Object.values(x)[0] == null;

/** Composes an object of filter definitions into a single filter predicate. */
const buildFilterFn = <T>(filters: FilterRecord<T>) => {
  const filterers = flatMap(omitBy(filters, isEmptyFilter), (arg, field) =>
    map(arg, (val, op) => {
      const pred = filterFns[op];
      return (obj: T) => {
        const x = obj[field];
        return x != null && pred(x, val);
      };
    }),
  );
  return filterers.length === 0 ? identity : overEvery(filterers);
};

type UnitConversionRecord<T> = {
  [K in keyof T as T[K] extends number | null | undefined ? K : never]?: {
    from: UnitName;
    to: UnitName;
  };
};

/** Composes an object of unit conversions into a single mapping function. */
const buildUnitMapperFn = <T>(units: UnitConversionRecord<T>) => {
  const mappers = map(omitBy(units, isUndefined), (arg, field) => {
    const { from, to } = arg!;
    const convert = unitConverter(from, to);
    return (obj: T) => {
      const x = obj[field];
      if (x != null) {
        // we're making a copy already so this is fine
        // eslint-disable-next-line no-param-reassign
        obj[field] = convert(x);
      }
      return obj;
    };
  });
  return mappers.length === 0 ? identity : flow([clone, ...mappers]);
};

interface XForm<T> {
  filter?: FilterRecord<T>;
  units?: UnitConversionRecord<T>;
}

const buildDataTransformer = <T>(xform: XForm<T>) => {
  const filterer = buildFilterFn(xform.filter ?? {});
  const mapper = buildUnitMapperFn(xform.units ?? {});
  // Special-case no transformation
  if (mapper === identity && filterer === identity) {
    return (data: T[]) => data;
  }
  return (data: T[]) =>
    data.reduce<T[]>((ret, d) => {
      const d2 = mapper(d);
      if (filterer(d2)) {
        ret.push(d2);
      }
      return ret;
    }, []);
};

/**
 * Builds a memoized data transformation function. You must supply the type
 * argument to get type safety here.
 *
 * @see useTransformedData for the transformer shape.
 */
export const useDataTransformer = <T>(xform?: XForm<T>) =>
  useDeepCompareMemo(
    () => (xform ? buildDataTransformer<T>(xform) : (d: T[]) => d),
    [xform],
  );

/**
 * Memoized hook that transforms data using a description of the
 * transformation. This is similar but not exactly the same as our API's
 * filtering query params like _where.
 *
 * @example
 * useTransformedData(data, {
 *   // Filters to apply to the data
 *   filter: {
 *     sample_date: { between: ["2022-01-01", "2022-03-01"] },
 *     sampling_location_name: { in: ["a", "b", "c"] },
 *     effective_concentration: { gt: 0 },
 *   },
 *   // unit conversions
 *   units: {
 *     effective_concentration: { from: "L", to: "mL" }
 *   }
 * })
 */
export const useTransformedData = <T>(data: T[], xform?: XForm<T>) => {
  const transformer = useDataTransformer(xform);
  return useMemo(() => transformer(data), [data, transformer]);
};

export default useTransformedData;
