interface QueryWrapperProps {
  data?: unknown;
  isLoading: boolean;
  children: React.ReactNode;
}

const QueryWrapper = ({ data, isLoading, children }: QueryWrapperProps) => {
  if (isLoading) {
    return <span>Loading...</span>;
  } else if (data) {
    return <>{children}</>;
  } else {
    return (
      <span>Unable to load data at this time. Please try again later.</span>
    );
  }
};

export default QueryWrapper;
