import { Alert, Spin, Table } from "antd";
import { map } from "lodash";

import api from "src/api";
import { numberSorter, stringSorter } from "src/util/table";

const IngestDetail = ({ logKey }: { logKey: number }) => {
  const { data, isLoading } = api.audit.load.detail.useQuery({ key: logKey });
  if (!data) {
    if (isLoading) {
      return <Spin />;
    } else {
      return <Alert message="Error loading details" />;
    }
  }
  const { source_info, table_info } = data.data;
  const sourceData = map(source_info, (models, sourceName) =>
    map(models, (model, name) => ({
      ...model,
      modelName: name,
      sourceName,
    })),
  ).flat();
  const buildData = map(table_info, (info, tableName) => ({
    tableName,
    ...info,
  }));
  return (
    <div>
      <p className="text-h2">Build info</p>
      <Table
        size="small"
        dataSource={buildData}
        rowKey="tableName"
        columns={[
          {
            title: "Table",
            dataIndex: "tableName",
            sorter: stringSorter("tableName"),
          },
          {
            title: "Build time (ms)",
            dataIndex: "build_ms",
            sorter: numberSorter("build_ms"),
          },
          {
            title: "Insert count",
            dataIndex: "insert_count",
            sorter: numberSorter("insert_count"),
          },
          {
            title: "Update count",
            dataIndex: "update_count",
            sorter: numberSorter("update_count"),
          },
        ]}
        scroll={{ x: "max-content" }}
        pagination={false}
      />
      <p className="text-h2 mt-16">Source load info</p>
      <Table
        size="small"
        dataSource={sourceData}
        rowKey={(row) => `${row.sourceName}.${row.modelName}`}
        columns={[
          {
            title: "Source",
            dataIndex: "sourceName",
            sorter: stringSorter("sourceName"),
          },
          {
            title: "Model",
            dataIndex: "modelName",
            sorter: stringSorter("modelName"),
          },
          {
            title: "Rows ingested",
            dataIndex: "rows",
            sorter: numberSorter("rows"),
          },
          {
            title: "Load time (ms)",
            dataIndex: "load_ms",
            sorter: numberSorter("load_ms"),
          },
          { title: "last_modified", dataIndex: "last_modified" },
          { title: "source_id", dataIndex: "source_id" },
        ]}
        scroll={{ x: "max-content" }}
        pagination={false}
      />
    </div>
  );
};

export default IngestDetail;
