import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import qs from "qs";
import {
  QueryClient,
  UseMutationResult,
  UseQueryResult,
  useQueries,
  useQueryClient,
} from "react-query";

import useAuth0Mutation, { UseMutationExtraOptions } from "./useAuth0Mutation";
import useAuth0Query, { UseQueryExtraOptions } from "./useAuth0Query";
import usePermissionOverride from "./usePermissionOverride";

/* eslint-disable @typescript-eslint/no-explicit-any */

// -- createRpcHooks output type --
// This is what gives us nice autocompletion, and allows react-query to infer
// the input and output types for the entire api. This all happens at the type
// level, nothing here happens at runtime.

type Rename<T, R extends Record<string, string>> = {
  [K in keyof T as K extends keyof R ? R[K] : K]: T[K];
};

type UseProxyFetch<T extends Route> = () => Rename<
  UseMutationResult<T["output"], unknown, T["input"], unknown>,
  { mutate: "fetch"; mutateAsync: "fetchAsync" }
>;

/** Creates an output type based on the _fields input param. */
type OutputForFields<TInput, TOutput> = TInput extends {
  _fields: (infer TFields)[];
}
  ? TOutput extends { data: (infer TData)[] }
    ? Omit<TOutput, "data"> & { data: Pick<TData, TFields & keyof TData>[] }
    : TOutput extends { data: infer TData }
      ? Omit<TOutput, "data"> & { data: Pick<TData, TFields & keyof TData> }
      : TOutput
  : TOutput;

type UseProxyQuery<T extends Route> = <
  TInput extends T["input"],
  TData = OutputForFields<TInput, T["output"]>,
>(
  input: Pick<TInput, keyof T["input"]>,
  options?: UseQueryExtraOptions<OutputForFields<TInput, T["output"]>, TData>,
) => UseQueryResult<TData, unknown>;

type UseProxyQueries<T extends Route> = <TData = T["output"]>(
  inputs: T["input"][],
  options?: UseQueryExtraOptions<T["output"], TData>,
) => UseQueryResult<TData, unknown>[];

type UseProxyMutation<T extends Route> = (
  options?: UseMutationExtraOptions<T["output"], T["input"]>,
) => UseMutationResult<T["output"], unknown, T["input"], unknown>;

type ApiProxy<T extends Router | Route> = T extends Route
  ? T["method"] extends "GET"
    ? {
        // GET endpoints turn into useQuery, useQueries, or useFetch
        useQuery: UseProxyQuery<T>;
        useQueries: UseProxyQueries<T>;
        useFetch: UseProxyFetch<T>;
        _input: T["input"];
        _output: T["output"];
      }
    : {
        // Other endpoints turn into useMutation
        useMutation: UseProxyMutation<T>;
        _input: T["input"];
        _output: T["output"];
      }
  : // Handle nested routes
    T extends Router
    ? { [K in keyof T]: ApiProxy<T[K]> }
    : never;

// -- createRpcHooks, the main entrypoint --

interface Route {
  method: "GET" | "PUT" | "POST" | "DELETE";
  input: object;
  output: unknown;
}

type Router = { [K in string]: Route | Router };

export interface CreateRpcHooksCfg {
  root: string;
  mutationOptions?: <A, B>(
    path: string[],
    opts: UseMutationExtraOptions<A, B> & { queryClient: QueryClient },
  ) => UseMutationExtraOptions<A, B>;
  queryOptions?: <A, B>(
    path: string[],
    opts?: UseQueryExtraOptions<A, B>,
  ) => UseQueryExtraOptions<A, B>;
}

/**
 * Creates an api proxy object for an RPC backend.
 *
 * @param cfg.root -- the root rpc endpoint
 * @param cfg.mutationOptions -- a function taking (path, opts) that should
 *   return an object of options to pass to useMutation. opts are the options
 *   passed in the useMutation hook call.
 * @param cfg.queryOptons -- a function taking (path, opts) that should return
 *   an object of options to pass to useMutation. opts are the options passed
 *   in the useMutation hook call.
 *
 * @example
 * type MyRouter = {
 *   talk: {
 *     hello: {
 *       method: "GET",
 *       input: { name: string },
 *       output: { greeting: string }
 *      }
 *   },
 * }
 * const api = createRpcHooks<MyRouter>({
 *   root = "https://my.api"
 * });
 *
 * // Api usage in a component. Note that api is fully typed, so you get
 * // autocomplete for all of this.
 * const GreetingComponent = ({ name }) => {
 *   const { data } = api.talk.hello.useQuery({ name });
 *   return <div>data?.greeting</div>
 * };
 */
const createRpcHooks = <TRouter extends Router>(cfg: CreateRpcHooksCfg) => {
  const request = async (opts: {
    path: string[];
    method?: string;
    accessToken: string;
    permissionOverride?: string;
    input: object;
  }) => {
    const method = opts.method?.toLowerCase() ?? "get";
    const body = method === "get" ? undefined : opts.input;
    const params = method === "get" ? opts.input : undefined;
    const { data } = await axios({
      url: `${cfg.root}/${opts.path.join(".")}`,
      method,
      headers: { Authorization: `Bearer ${opts.accessToken}` },
      params: { ...params, permission_override: opts.permissionOverride },
      paramsSerializer: qs.stringify,
      data: body,
    });
    return data;
  };

  // The actual react-query hooks. `path` here is the path to the actual route,
  // e.g. ["covid", "qpcr"] results in a query to the getCovidQpcr backend
  // handler, or ["covid", "release"] results in a mutation to the
  // releaseCovidQpcr backend handler.
  const hookFactories = {
    useFetch<T extends Route>(path: string[]): UseProxyFetch<T> {
      return function useRpcFetch() {
        const mutation = useAuth0Mutation<T["output"], T["input"]>((args) =>
          request({ path, ...args }),
        );
        const { mutate, mutateAsync, ...rest } = mutation;
        return { ...rest, fetch: mutate, fetchAsync: mutateAsync };
      };
    },
    useQuery<T extends Route>(path: string[]): UseProxyQuery<T> {
      return function useRpcQuery(input, options) {
        return useAuth0Query({
          queryKey: [{ root: cfg.root, path, method: "get", input }],
          queryFn: ({ queryKey, accessToken }) =>
            request({ accessToken, ...queryKey[0] }),
          ...options,
          ...cfg.queryOptions?.(path, options),
        });
      };
    },
    useQueries<T extends Route>(path: string[]): UseProxyQueries<T> {
      return function useRpcQueries(
        inputs,
        { customerOverride, ...options } = {},
      ) {
        // can't useAuth0Query here, so we have to do it manually :(
        const { getAccessTokenSilently } = useAuth0();
        const permissionOverride = usePermissionOverride(
          customerOverride !== false,
        );
        const opts = { ...cfg.queryOptions?.(path, options), ...options };
        return useQueries(
          inputs.map((input) => ({
            queryKey: [
              {
                root: cfg.root,
                path,
                method: "get",
                input,
                permissionOverride,
              },
            ],
            async queryFn({ queryKey }) {
              const accessToken = await getAccessTokenSilently();
              return request({ accessToken, ...queryKey[0] });
            },
            ...opts,
          })) as any[],
        ) as any;
      };
    },
    useMutation<T extends Route>(path: string[]): UseProxyMutation<T> {
      return function useRpcMutation(options) {
        const queryClient = useQueryClient();
        return useAuth0Mutation<T["output"], T["input"]>(
          (args) => request({ path, method: "post", ...args }),
          {
            ...options,
            ...cfg.mutationOptions?.(path, { queryClient, ...options }),
          },
        );
      };
    },
  };

  /* eslint-disable */
  // This Proxy is what deals with creating query hooks at runtime.
  const apiProxy = <T extends Router | Route>(path: string[] = []) =>
    new Proxy({} as ApiProxy<T>, {
      get<K extends keyof ApiProxy<T> & string>(obj: ApiProxy<T>, prop: K) {
        if (!(prop in obj)) {
          if (prop in hookFactories) {
            (obj as any)[prop] = (hookFactories as any)[prop](path);
          } else if (typeof prop === "string") {
            const fullPath = [...path, prop];
            const nested = apiProxy(fullPath);
            (obj as any)[prop] = nested;
          }
        }
        return obj[prop];
      },
    });
  /* eslint-enable */

  return apiProxy<TRouter>();
};

export default createRpcHooks;
