import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [{ label: "Carfentanil", value: "Carfentanil" }];

const aboutTheData = (
  <>
    <p>
      Carfentanil is an extremely potent synthetic opioid and fentanyl analog.
      It is approximately 10,000 times more potent than morphine and 100 times
      more potent than fentanyl. It is used primarily as a tranquilizer for
      large animals and is not intended for human use but has appeared as a
      contaminant in illicit drug supplies. Carfentanil is excreted in urine but
      is excreted in low concentrations and may degrade quickly in wastewater.
      Upstream sampling locations are therefore recommended for monitoring
      sufentanil use.
    </p>
    <p>
      Due to its extreme potency, even microgram amounts can be lethal to
      humans.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const CarfentanilPage = () => {
  const { data, isLoading } = api.data.carfentanilWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default CarfentanilPage;
