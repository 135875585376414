import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [{ label: "Codeine", value: "Codeine" }];

const aboutTheData = (
  <>
    <p>
      Codeine is an opioid used to treat mild to moderate pain and, in
      combination with other medications, as a cough suppressant. It is
      available in various formulations, including immediate- and
      extended-release tablets and syrups. Life-threatening overdoses are
      possible, and prolonged use can result in physical dependence. Codeine is
      excreted in urine and stable in wastewater.
    </p>
    <p>
      Medical doses range from 15 mg to 60 mg per administration, with a maximum
      daily dose of 360 mg per day.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const CodeinePage = () => {
  const { data, isLoading } = api.data.codeineWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default CodeinePage;
