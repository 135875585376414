import { Spin } from "antd";
import { createContext, useContext } from "react";

import api, { type ApiOutput } from "src/api";
import FatalError from "src/components/FatalError";
import { useRoleCheck } from "src/util/hooks";

export type FeatureFlags = ApiOutput<"featureFlag.getFrontend">["flags"];

export const FeatureFlagContext = createContext<FeatureFlags | undefined>(
  undefined,
);

export const useFeatureFlags = () => {
  const value = useContext(FeatureFlagContext);
  if (value == null) {
    throw new Error("useFeatureFlags must be used in FeatureFlag context");
  }
  return value;
};

export const useFeatureFlagQuery = () => {
  // If the user has the power to change feature flags, we want them to always
  // see updated flags on the frontend.
  const featureFlagger = useRoleCheck("biobot-feature-flagger");
  return api.featureFlag.getFrontend.useQuery(
    featureFlagger ? { bustCache: true } : {},
  );
};

export const FeatureFlagProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const query = useFeatureFlagQuery();
  if (query.data) {
    return (
      <FeatureFlagContext.Provider value={query.data.flags}>
        {children}
      </FeatureFlagContext.Provider>
    );
  } else if (query.isLoading) {
    return <Spin />;
  } else {
    return <FatalError message="Error fetching application configuration" />;
  }
};
