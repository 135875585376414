import {
  AxisBottom,
  AxisLeft,
  AxisRight,
  AxisTop,
  TickRendererProps,
} from "@visx/axis";
import { GridColumns, GridRows } from "@visx/grid";
import type { AnyD3Scale } from "@visx/scale";
import { Text } from "@visx/text";
import { format } from "d3-format";
import { utcFormat } from "d3-time-format";
import { omit } from "lodash";
import { useMemo } from "react";

import { useChartData } from "./ChartData";
import { useChartSize } from "./ChartSize";
import { useChartTheme } from "./ChartTheme";

/** A Tick component that respects the parent's style. */
const StyledTick = ({ formattedValue, ...props }: TickRendererProps) => (
  <Text className="fill-current font-[inherit]" {...props} fontSize={undefined}>
    {formattedValue}
  </Text>
);

const axes = {
  top: AxisTop,
  left: AxisLeft,
  right: AxisRight,
  bottom: AxisBottom,
} as const;

export type Orientation = keyof typeof axes;
type CommonAxisProps = Parameters<typeof AxisTop>[0];

type TickFormatter = Exclude<CommonAxisProps["tickFormat"], undefined>;

interface AllAxisProps extends Omit<CommonAxisProps, "scale" | "tickFormat"> {
  orientation: Orientation;
  scale?: CommonAxisProps["scale"];
  axis?: "x" | "y";
  scaleIdx?: number;
  grid?: boolean;
  gridLineClassName?: string;
  tickLineClassName?: string;
  tickFormat?: TickFormatter | string;
  zeroTick?: string;
}

export type AxisProps = AllAxisProps &
  (
    | Required<Pick<AllAxisProps, "scale">>
    | Required<Pick<AllAxisProps, "axis">>
  );

const makeFormatter = (
  fmt: TickFormatter | string | undefined,
  scale: AnyD3Scale,
): TickFormatter => {
  if (typeof fmt === "string") {
    if (scale.domain()[0] instanceof Date) {
      return utcFormat(fmt) as TickFormatter;
    } else {
      return format(fmt);
    }
  } else if (fmt) {
    return fmt;
  } else if ("tickFormat" in scale) {
    return scale.tickFormat();
  } else {
    return ((value: unknown) => `${value}`) as TickFormatter;
  }
};

const useFormatter = (
  fmt: TickFormatter | string | undefined,
  scale: AnyD3Scale,
  zeroTick?: string,
): TickFormatter =>
  useMemo(() => {
    const formatter = makeFormatter(fmt, scale);
    if (zeroTick) {
      return (value, index, allTicks) =>
        value === 0 ? zeroTick : formatter(value, index, allTicks);
    } else {
      return formatter;
    }
  }, [fmt, scale, zeroTick]);

interface GuessTicksProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scale: AnyD3Scale & { ticks: () => any[] };
  width: number;
  height: number;
  tickFormat: TickFormatter;
  isHorizontal?: boolean;
}

type GuessTicksInnerProps = Omit<GuessTicksProps, "isHorizontal">;

const guessHorizontalTicks = ({ scale, tickFormat }: GuessTicksInnerProps) => {
  // eslint-disable-next-line no-plusplus
  for (let n = 10; n > 0; n--) {
    const ticks = scale.ticks(n);
    if (ticks.length === 1) {
      // With 1 tick the width calculation below doesn't work, so we assume
      // that there is space for one tick.
      return n;
    }
    const width = Math.abs(scale(ticks[0]) - scale(ticks[ticks.length - 1]));
    const allTicks = ticks.map((value, index) => ({ value, index }));
    const chars = ticks
      .map((value, index) => tickFormat(value, index, allTicks))
      .join(" ").length;
    if (chars < width / 8) {
      return n;
    }
  }
  return 0;
};

const guessVerticalTicks = ({ height }: GuessTicksInnerProps) => {
  // This is based on eyeballing a few tick numbers and then doing an exponential
  // regression
  const guess = Math.round(1.005 ** height + 1.5);
  return Math.min(10, Math.max(guess, 0));
};

/**
 * Approximates a decent-looking number of ticks for an axis, trying to avoid
 * any overlapping. This is a bit crude -- consider passing `numTicks` to the
 * axis component if you need more control.
 */
const guessTicks = ({
  scale,
  tickFormat,
  isHorizontal,
  width,
  height,
}: GuessTicksProps) => {
  if (!("ticks" in scale)) {
    return 0;
  }
  if (isHorizontal) {
    return guessHorizontalTicks({ scale, tickFormat, width, height });
  } else {
    return guessVerticalTicks({ scale, tickFormat, width, height });
  }
};

const scaleMissingError = (axis: string, idx: number) =>
  new Error(
    `No ${axis} scale exists with index ${idx}; pick a different scaleIdx, or add another ${axis}Scale.`,
  );

const useAxisTheme = (axis?: "x" | "y") => {
  const theme = useChartTheme();
  return {
    ...theme.axis,
    ...(axis === "x" ? theme.xAxis : undefined),
    ...(axis === "y" ? theme.yAxis : undefined),
  };
};

/** An enhanced Axis component.
 *
 * - Uses theming @see {@link ChartThemeProvider}
 * - Allows customizing tick font, size, and color using classes
 * - Allows passing a tickFormat string instead of a function
 * - Includes grid lines, customizable with `gridLineClassName`
 */
export const Axis = ({
  orientation,
  scaleIdx,
  axis,
  scale: scaleProp,
  grid,
  tickFormat,
  zeroTick,
  gridLineClassName,
  numTicks: numTicksProp,
  ...props
}: AxisProps) => {
  const { width, height } = useChartSize();
  const { xScales, yScales } = useChartData();
  const theme = useAxisTheme(axis);
  const AxisComponent = axes[orientation];
  const isHorizontal = orientation === "bottom" || orientation === "top";
  // figure out which scale to use
  let scale = scaleProp;
  if (!scale) {
    if (axis === "y") {
      // use the "normal" position (left) for scale 0
      const defaultIdx = orientation === "left" ? 0 : 1;
      scale = yScales[scaleIdx ?? defaultIdx];
      if (!scale) throw scaleMissingError("y", scaleIdx ?? defaultIdx);
    } else {
      // use the "normal" position (bottom) for scale 0
      const defaultIdx = orientation === "bottom" ? 0 : 1;
      scale = xScales[scaleIdx ?? defaultIdx];
      if (!scale) throw scaleMissingError("x", scaleIdx ?? defaultIdx);
    }
  }
  const tickFmt = useFormatter(tickFormat, scale, zeroTick);
  const numTicks =
    numTicksProp == null && props.tickValues == null && "ticks" in scale
      ? guessTicks({ scale, tickFormat: tickFmt, isHorizontal, width, height })
      : numTicksProp;
  return (
    <>
      {/* grid first so that axis line is on top of grid line */}
      {(grid ?? theme.grid) &&
        (isHorizontal ? (
          <GridColumns
            scale={scale}
            height={height}
            numTicks={numTicks}
            tickValues={props.tickValues}
            stroke="inherit"
            strokeWidth="inherit"
            className={gridLineClassName ?? theme.gridLineClassName}
          />
        ) : (
          <GridRows
            scale={scale}
            width={width}
            numTicks={numTicks}
            tickValues={props.tickValues}
            stroke="inherit"
            strokeWidth="inherit"
            className={gridLineClassName ?? theme.gridLineClassName}
          />
        ))}
      <AxisComponent
        top={orientation === "bottom" ? height : 0}
        left={orientation === "right" ? width : 0}
        scale={scale}
        tickComponent={StyledTick}
        tickFormat={tickFmt}
        numTicks={numTicks}
        {...omit(theme, ["gridLineClassName", "grid"])}
        {...props}
      />
    </>
  );
};

/** The XAxis. Defaults to displaying the first x scale as the bottom axis. */
export const XAxis = (props: Partial<AllAxisProps>) => (
  <Axis orientation="bottom" axis="x" {...props} />
);

/** The YAxis. Defaults to displaying the first y scale as the left axis. */
export const YAxis = (props: Partial<AllAxisProps>) => (
  <Axis orientation="left" axis="y" {...props} />
);
