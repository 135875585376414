import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [{ label: "Oxymorphone", value: "Oxymorphone" }];

const aboutTheData = (
  <>
    <p>
      Oxymorphone is a semi-synthetic opioid used to treat severe pain. It is
      available in immediate-release and extended-release formulations and is
      typically administered orally. Life-threatening overdoses are possible,
      and prolonged has high potential for physical dependence. Oxymorphone is
      excreted in urine and stable in wastewater.
    </p>
    <p>
      Prescription doses vary, typically ranging from 5 mg to 20 mg for pain
      relief, with higher doses used in extended-release formulations.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const OxymorphonePage = () => {
  const { data, isLoading } = api.data.oxymorphoneWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default OxymorphonePage;
