import NoData from "src/components/NoData";
import QueryWrapper from "src/components/QueryWrapper";
import { useCustomer } from "src/context/Customer";
import hrsImg from "src/images/hrs_molecule.png";

import LCMSLocationsCard, { type LCMSLocationsText } from "./LCMSLocationsCard";
import LCMSSummaryCard, { type LCMSSummaryText } from "./LCMSSummaryCard";
import { parentName } from "./text";
import type { Compound, LCMSDatum } from "./util";

export interface LCMSCommandCenterText {
  targetName?: string;
  summaryCard?: LCMSSummaryText;
  locationsCard?: LCMSLocationsText;
}

interface LCMSCommandCenterProps {
  data?: LCMSDatum[];
  isLoading: boolean;
  compounds: readonly Compound[];
  text: LCMSCommandCenterText;
}

const LCMSCommandCenter = ({
  data,
  isLoading,
  compounds,
  text,
}: LCMSCommandCenterProps) => {
  const { customer_name } = useCustomer();
  const targetName = text.targetName ?? parentName(compounds);
  const hasLocationsCard = compounds.length > 1;

  return (
    <div>
      <h1 className="text-h1">
        {targetName} Command Center for {customer_name}
      </h1>
      <QueryWrapper data={data} isLoading={isLoading}>
        {data?.length ? (
          <div className="space-y-5">
            <LCMSSummaryCard
              data={data}
              compounds={compounds}
              {...text.summaryCard}
            />
            {hasLocationsCard && (
              <LCMSLocationsCard
                data={data}
                compounds={compounds}
                {...text.locationsCard}
              />
            )}
          </div>
        ) : (
          <NoData target={targetName} img={hrsImg} />
        )}
      </QueryWrapper>
    </div>
  );
};

export default LCMSCommandCenter;
