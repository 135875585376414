import { User, useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const TRACKING_ID = "G-00Q77H61GZ";
const isProduction = window.location.origin === "https://explore.biobot.io";

const useInternalEmployeeCheck = (user: User | undefined) => {
  const hasBiobotRole =
    user?.["https://api.biobot.io/roles"].findIndex((role: string | string[]) =>
      role.includes("biobot"),
    ) >= 0;
  const hasBiobotEmail = user?.email?.includes("biobot.io");
  const isInternalEmployee = hasBiobotRole || hasBiobotEmail;
  return isInternalEmployee;
};

export const useInit = (user: User | undefined) => {
  const isInternalEmployee = useInternalEmployeeCheck(user);
  return ReactGA.initialize(TRACKING_ID, {
    testMode:
      !isProduction ||
      typeof isInternalEmployee === "undefined" ||
      isInternalEmployee,
  });
};

export const sendPageview = (path: string) =>
  ReactGA.send({
    hitType: "pageview",
    page: path,
  });

export const useGoogleAnalytics = () => {
  const location = useLocation();
  const { user } = useAuth0();

  useInit(user);

  useEffect(() => {
    const path = location.pathname + location.search;
    sendPageview(path);
  }, [location]);
};

export default useGoogleAnalytics;
