import dayjs from "dayjs";

import type { IsoDate } from "~api/types/util";

import DateStringPicker, {
  DateStringRangePickerProps,
} from "./DateStringPicker";

// We use non-nullable start/end in most places in the app, but antd allows
// nullable start/end for open ranges. This file presents a slightly different
// interface on top of DateStringPicker.RangePicker that requires non-nullable
// start/end.
export type DateRange = [IsoDate, IsoDate] | null;

const rangePresets: DateStringRangePickerProps["presets"] = [
  { label: "Last Month", value: [dayjs().subtract(1, "month"), dayjs()] },
  { label: "Last 3 Months", value: [dayjs().subtract(3, "month"), dayjs()] },
  { label: "Last 6 Months", value: [dayjs().subtract(6, "month"), dayjs()] },
  { label: "Last Year", value: [dayjs().subtract(1, "year"), dayjs()] },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { label: "Total", value: null as any },
];

interface DateRangePickerWithPresetsProps
  extends Omit<DateStringRangePickerProps, "value" | "onChange"> {
  value?: DateRange;
  onChange?: (dates: DateRange) => void;
}

const DateRangePickerWithPresets = (props: DateRangePickerWithPresetsProps) => (
  <DateStringPicker.RangePicker
    data-test="viz-rangepicker"
    disabledDate={(current) => current && current.valueOf() > Date.now()}
    presets={rangePresets}
    {...(props as DateStringRangePickerProps)}
  />
);

export default DateRangePickerWithPresets;
