import { Form } from "antd";

import BigSelect from "src/components/BigSelect";
import DateStringPicker from "src/components/DateStringPicker";

import type { DataFilterComponent, DataFilterComponentProps } from "./types";
import { formGridClass, formSelectProps, validateEndDate } from "./utils";

const RegionalDataFilter: DataFilterComponent<"regional"> = ({
  name,
  options,
}: DataFilterComponentProps) => (
  <div className={formGridClass}>
    <p className="leading-8">
      <span className="text-coral-5">* </span>Datasets
    </p>
    <Form.Item name={[...name, "dataset_name"]} rules={[{ required: true }]}>
      <BigSelect options={options.dataset_name.regional} {...formSelectProps} />
    </Form.Item>
    <p className="leading-8">
      <span className="text-coral-5">* </span>Regions
    </p>
    <Form.Item name={[...name, "census_region"]} rules={[{ required: true }]}>
      <BigSelect options={options.census_region} {...formSelectProps} />
    </Form.Item>
    <p className="leading-8">Sample date start</p>
    <Form.Item name={[...name, "start_date"]}>
      <DateStringPicker />
    </Form.Item>
    <p className="leading-8">Sample date end</p>
    <Form.Item
      name={[...name, "end_date"]}
      rules={[{ validator: (_, val) => validateEndDate(val) }]}
    >
      <DateStringPicker />
    </Form.Item>
  </div>
);

RegionalDataFilter.defaultFilter = {
  filter_type: "regional",
};

export default RegionalDataFilter;
