import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

export const compounds = [
  { label: "Morphine", value: "Morphine" },
  { label: "Morphine-3-glucuronide", value: "Morphine-3-glucuronide" },
];

const aboutTheData = (
  <>
    <p>
      Morphine is a naturally occurring opiate used medically for severe pain
      management and is also found in illicit drug markets. It can be
      administered intravenously or orally. Morphine and its metabolites,
      including morphine-3-glucuronide, are excreted in urine but degrade
      quickly in wastewater. Upstream sampling locations are recommended for
      monitoring morphine use.
    </p>
    <p>
      Doses vary depending on the method of administration and patient
      tolerance, with medical doses often ranging from 10 mg to 30 mg, though
      recreational doses may exceed 30 mg.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const MorphinePage = () => {
  const { data, isLoading } = api.data.morphineWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default MorphinePage;
