import { Button, Typography } from "antd";

import api from "src/api";
import PageTitle from "src/components/PageTitle";
import QueryWrapper from "src/components/QueryWrapper";
import useReauthNavigate from "src/hooks/useReauthNavigate";

import ApiTokenCard from "./ApiTokenCard";

const useGenerateTokenCallback = () => {
  const reauthNavigate = useReauthNavigate();
  return () => {
    reauthNavigate({
      to: "/settings/tokens/new",
      maxAge: 300, // 5 minutes
      prompt: {
        title: "Reauthentication required",
        content:
          "You will be prompted to log in again before generating a new token",
      },
    });
  };
};

const NoTokens = ({ onGenerateToken }: { onGenerateToken: () => void }) => (
  <p>
    You do you have any API Tokens.{" "}
    <Typography.Link onClick={onGenerateToken}>
      Generate a new API Token
    </Typography.Link>{" "}
    to get started with the Biobot Platform API.
  </p>
);

const ApiTokenListPage = () => {
  const { data, isLoading } = api.user.listTokens.useQuery({});
  const handleGenerateToken = useGenerateTokenCallback();
  return (
    <div>
      <div className="flex justify-between items-center">
        <PageTitle.Heading back="/settings">API Tokens</PageTitle.Heading>
        <Button onClick={handleGenerateToken}>Generate New Token</Button>
      </div>
      <QueryWrapper data={data} isLoading={isLoading}>
        {data?.data.length ? (
          <div className="space-y-2">
            {data.data.map((token) => (
              <ApiTokenCard key={token.key} token={token} allowDelete />
            ))}
          </div>
        ) : (
          <NoTokens onGenerateToken={handleGenerateToken} />
        )}
      </QueryWrapper>
    </div>
  );
};

export default ApiTokenListPage;
