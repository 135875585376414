import { EditOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Link } from "react-router-dom";

import api, { type ApiOutput } from "src/api";
import {
  useCustomerOverride,
  useSetCustomerOverride,
} from "src/context/Customer";
import { useRoleCheck } from "src/util/hooks";

const asCustomerOptions = ({ data }: ApiOutput<"customer.list">) =>
  data.map((c) => ({
    label: `${c.customer_name} (${c.customer_code})`,
    value: c.customer_code,
  }));

/**
 * Form for selecting admin permission overrides. Only visible for admin users
 * on pages that use {@link CustomerProvider}.
 */
const CustomerOverridePicker = () => {
  const { customerCode, customerMode } = useCustomerOverride();
  const setCustomerOverride = useSetCustomerOverride();
  const { data: customerOptions } = api.customer.list.useQuery(
    {},
    { select: asCustomerOptions, customerOverride: false },
  );
  if (!setCustomerOverride || customerMode) {
    // We are not in a admin context (e.g. the data review page), or we are in
    // customer mode
    return null;
  }
  return (
    <div className="h-full flex items-center">
      {customerCode && (
        <Link
          to={`/customers/${customerCode}`}
          title="Edit customer"
          className="text-xl text-white mr-4"
        >
          <EditOutlined />
        </Link>
      )}
      <Select
        allowClear
        maxTagCount="responsive"
        data-test="customer-picker"
        maxTagTextLength={10}
        value={customerCode}
        options={customerOptions}
        onChange={(vals) =>
          setCustomerOverride((prev) => ({
            ...prev,
            customerCode: vals,
          }))
        }
        optionFilterProp="label"
        showSearch
        dropdownMatchSelectWidth={false}
        className="min-w-[15rem] max-w-[25rem]"
      />
    </div>
  );
};

const CustomerOverridePickerWrapper = () => {
  const isAdmin = useRoleCheck("biobot-admin");
  if (!isAdmin) {
    return null;
  }
  return <CustomerOverridePicker />;
};

export default CustomerOverridePickerWrapper;
