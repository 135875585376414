import { Navigate, Outlet } from "react-router-dom";

import { RoleName, useRoleCheck } from "src/util/hooks";

export interface RoleProtectedRouteProps {
  roleName: RoleName | RoleName[];
}

const RoleProtectedRoute = ({ roleName }: RoleProtectedRouteProps) => {
  const hasRole = useRoleCheck(roleName);

  if (hasRole) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

export default RoleProtectedRoute;
