import {
  CheckOutlined,
  DownloadOutlined,
  LoadingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import cx from "classnames";
import ReactGA from "react-ga4";

import useExportDownload from "./useExportDownload";

/* eslint-disable no-nested-ternary */

export interface DownloadLinkProps extends ButtonProps {
  label: string;
  gaAction?: string;
  buttonClass?: string;
  extra?: React.ReactNode;
}

/** A link used for a static download (e.g. a data dictionary). */
export const DownloadLink = ({
  buttonClass,
  className,
  gaAction,
  icon,
  label,
  extra,
  onClick,
  ...props
}: DownloadLinkProps) => (
  <div
    className={cx(
      "p-2 border-0 border-b border-solid border-navy-4",
      className,
    )}
  >
    <div className="flex items-center gap-4 text-navy-9">
      <Button
        data-test="data-export-btn"
        type="default"
        shape="circle"
        size="small"
        role="button"
        className={cx("text-navy-9", buttonClass)}
        onClick={(e) => {
          if (gaAction) {
            ReactGA.event({ category: "File Download", action: gaAction });
          }
          return onClick?.(e);
        }}
        icon={icon ?? <DownloadOutlined />}
        {...props}
      />
      <div className="text-p1">{label}</div>
    </div>
    {extra}
  </div>
);

export interface ExportButtonProps extends Omit<ButtonProps, "href"> {
  label: string;
  href?: string | null;
  gaAction?: string;
  loading?: boolean;
}

/** A button used for a data export. */
export const ExportButton = ({
  href,
  loading,
  onClick,
  ...props
}: ExportButtonProps) => {
  const {
    download,
    isLoading: linkLoading,
    isSuccess: linkSuccess,
    isError: linkError,
    failureCount: linkFailureCount,
  } = useExportDownload();

  if (!href && !loading) {
    return null;
  }

  const icon = linkError ? (
    <WarningOutlined />
  ) : loading || linkLoading ? (
    <LoadingOutlined />
  ) : linkSuccess ? (
    <CheckOutlined />
  ) : (
    <DownloadOutlined />
  );

  const extra = linkError ? (
    <div className="text-coral-7">download failed, please try again</div>
  ) : linkLoading && linkFailureCount > 0 ? (
    <div className="text-yellow-7">still preparing your download...</div>
  ) : linkLoading ? (
    <div className="text-biobotblue-6">preparing your download...</div>
  ) : null;

  return (
    <DownloadLink
      type="primary"
      buttonClass={cx({
        "bg-biobotblue-3": linkLoading,
        "bg-coral-4": linkError,
        "bg-lime-5": linkSuccess,
      })}
      onClick={(e) => {
        if (href) {
          download(href);
        }
        return onClick?.(e);
      }}
      loading={loading || linkLoading}
      disabled={loading || linkLoading}
      icon={icon}
      extra={extra}
      {...props}
    />
  );
};
