import { useState } from "react";

import api from "src/api";
import type { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import NoData from "src/components/NoData";
import QueryWrapper from "src/components/QueryWrapper";
import { useCustomer } from "src/context/Customer";
import noro1 from "src/images/norovirus-1.png";

import NoroLocationsCard from "./NoroLocationsCard";
import NoroSummaryCard from "./NoroSummaryCard";

const NoroPage = () => {
  const { customer_name } = useCustomer();
  const { data, isLoading } = api.data.norovirusWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "primer_target",
      "sample_date",
      "effective_concentration",
    ],
  });

  const { data: lastUpdateData } =
    api.norovirus.wastewater.lastUpdatedAt.useQuery({});

  const [concentrationUnit, setConcentrationUnit] =
    useState<Concentration>("1/L");

  return (
    <div>
      <h1 className="text-h1">Norovirus Command Center for {customer_name}</h1>
      <QueryWrapper data={data} isLoading={isLoading}>
        {data?.data?.length ? (
          <div className="space-y-5">
            <NoroSummaryCard
              data={data.data}
              concentrationUnit={concentrationUnit}
              setConcentrationUnit={setConcentrationUnit}
            />
            <NoroLocationsCard
              data={data.data}
              concentrationUnit={concentrationUnit}
              setConcentrationUnit={setConcentrationUnit}
              dateLastUpdated={lastUpdateData?.last_update}
            />
          </div>
        ) : (
          <NoData target="Norovirus" img={noro1} />
        )}
      </QueryWrapper>
    </div>
  );
};

export default NoroPage;
