import {
  Button,
  Checkbox,
  Col,
  Radio,
  RadioGroupProps,
  Row,
  Table,
  Tooltip,
  TooltipProps,
  Typography,
} from "antd";
import { useEffect, useState } from "react";

interface ButtonData {
  size: RadioGroupProps["size"];
  label: string;
  disabled?: boolean;
}

const TooltipDemo = () => {
  // prettier-ignore
  const placements: (TooltipProps["placement"] | null)[] = [
    null         , "topLeft"    , "top"    , "topRight"    , null          ,
    "leftTop"    , null         , null     , null          , "rightTop"    ,
    "left"       , null         , null     , null          , "right"       ,
    "leftBottom" , null         , null     , null          , "rightBottom" ,
    null         , "bottomLeft" , "bottom" , "bottomRight" , null          ,
  ];
  const [showTooltips, setShowTooltips] = useState<boolean>();
  useEffect(() => {
    if (showTooltips === false) {
      // We need one render at `false` to kick the tooltip visible state, and
      // then we want to reset to `undefined` so that the tooltips still show
      // on hover.
      setTimeout(() => setShowTooltips(undefined), 500);
    }
  }, [showTooltips, setShowTooltips]);
  return (
    <div className="mx-12">
      <Checkbox
        checked={showTooltips}
        onChange={() => setShowTooltips(!showTooltips)}
      >
        Show tooltips
      </Checkbox>
      <Row className="mt-12 p-4" gutter={[16, 32]}>
        {placements.map((placement, idx) => (
          <Col span={4} offset={idx % 5 === 0 ? 2 : 0} key={idx}>
            {placement && (
              <Tooltip
                title="Multi-line tooltip"
                placement={placement}
                open={showTooltips}
                overlayClassName="max-w-[6rem]" // force a line break
              >
                <div className="border-2 border-solid border-navy-9 p-2 max-w-[8rem]">
                  Tooltip target ({placement}).
                </div>
              </Tooltip>
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

const ButtonDemo = () => {
  const buttonData: ButtonData[] = [
    {
      label: "Large button",
      size: "large",
    },
    {
      label: "Medium (default) button",
      size: "middle",
    },
    {
      label: "Disabled button",
      size: "middle",
      disabled: true,
    },
    {
      label: "Small button",
      size: "small",
    },
  ];

  return (
    <Table
      scroll={{ x: "max-content" }}
      bordered
      pagination={false}
      dataSource={buttonData}
      rowKey="label"
      columns={[
        {
          title: "Description",
          dataIndex: "label",
        },
        {
          title: "Primary",
          key: "primary",
          render: ({ size, disabled }) => (
            <Button size={size} disabled={disabled} type="primary">
              Button
            </Button>
          ),
        },
        {
          title: "Default",
          key: "primary",
          render: ({ size, disabled }) => (
            <Button size={size} disabled={disabled}>
              Button
            </Button>
          ),
        },
        {
          title: "Link",
          key: "link",
          render: ({ size, disabled }) => (
            <Button size={size} disabled={disabled} type="link">
              Link
            </Button>
          ),
        },
        {
          title: "Radio",
          key: "radio",
          render: ({ size, disabled }) => (
            <Radio.Group
              size={size}
              options={[
                { label: "option 1", value: 1 },
                { label: "option 2", value: 2 },
                { label: "option 3", value: 3 },
              ]}
              defaultValue={1}
              optionType="button"
              buttonStyle="solid"
              disabled={disabled}
            />
          ),
        },
      ]}
    />
  );
};

const textSizes = [
  "text-8xl",
  "text-7xl",
  "text-6xl",
  "text-5xl",
  "text-4xl",
  "text-3xl",
  "text-2xl",
  "text-xl",
  "text-lg",
  "text-base",
  "text-sm",
  "text-xs",
];

const loremIpsum = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Fusce id velit ut tortor pretium viverra. Arcu vitae elementum curabitur vitae. Egestas quis ipsum suspendisse ultrices gravida dictum. Interdum posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Nisl condimentum id venenatis a condimentum vitae. Amet luctus venenatis lectus magna fringilla urna porttitor rhoncus. Nunc eget lorem dolor sed viverra ipsum. Nibh cras pulvinar mattis nunc sed blandit libero volutpat. Orci nulla pellentesque dignissim enim sit amet venenatis urna. Et ligula ullamcorper malesuada proin libero nunc consequat interdum varius.",
  "Augue mauris augue neque gravida in fermentum. In iaculis nunc sed augue lacus viverra vitae. At volutpat diam ut venenatis tellus in metus vulputate. Pellentesque elit ullamcorper dignissim cras. Rhoncus dolor purus non enim praesent elementum. Nunc mi ipsum faucibus vitae aliquet nec ullamcorper sit. Dui id ornare arcu odio ut sem nulla pharetra diam. Scelerisque eu ultrices vitae auctor eu augue. Mattis aliquam faucibus purus in massa. Vel pharetra vel turpis nunc.",
];

const TypographyDemo = () => (
  <div>
    <Typography.Title level={3}>Text sizes</Typography.Title>
    {textSizes.map((size) => (
      <div key={size} className="my-2">
        <Typography.Text className={size}>className={size}</Typography.Text>
      </div>
    ))}
    <Typography.Title level={3}>Monospace</Typography.Title>
    <div className="my-2">
      <Typography.Text code>Code style</Typography.Text>
    </div>
    <div className="my-2">
      <Typography.Text keyboard>Keyboard style</Typography.Text>
    </div>
    <Typography.Title level={3}>Headings</Typography.Title>
    <div className="p-12">
      <Typography.Title level={1}>Level 1</Typography.Title>
      <Typography.Title level={2}>Level 2</Typography.Title>
      <Typography.Title level={3}>Level 3</Typography.Title>
      <Typography.Title level={4}>Level 4</Typography.Title>
    </div>
    <Typography.Title level={3}>Paragraphs</Typography.Title>
    <Typography.Paragraph>{loremIpsum[0]}</Typography.Paragraph>
    <Typography.Paragraph>{loremIpsum[1]}</Typography.Paragraph>
  </div>
);

const ThemeDemo = () => (
  <div className="p-8">
    <Typography.Title level={1}>Theme Demo</Typography.Title>
    <Typography.Title level={2} className="mt-8">
      Buttons
    </Typography.Title>
    <ButtonDemo />
    <Typography.Title level={2} className="mt-8">
      Tooltips
    </Typography.Title>
    <TooltipDemo />
    <Typography.Title level={2} className="mt-8">
      Typography
    </Typography.Title>
    <TypographyDemo />
  </div>
);

export default ThemeDemo;
