import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [
  { label: "Tianeptine", value: "Tianeptine" },
  { label: "Tianeptine MC5 Metabolite", value: "Tianeptine MC5 Metabolite" },
];

const summarySubtitle =
  "Select multiple locations and toggle between tianeptine (the parent drug) or the tianeptine MC5 metabolite, to examine trends over time and across locations.";

const aboutTheData = (
  <>
    <p>
      Tianeptine is an atypical tricyclic antidepressant with opioid-like
      effects at higher doses. It is not approved for medical use in the United
      States but is sometimes misused for its psychoactive effects. It is often
      referred to as ZaZa, Tianna Red, or “gas station heroin” due to its
      unregulated availability in small shops. There is high potential for
      physical dependence, but a low likelihood of life-threatening overdose.
      Tianeptine and the tianeptine MC5 metabolite are excreted in urine and are
      moderately stable in wastewater. Upstream sampling is recommended for
      monitoring the use of tianeptine.
    </p>
    <p>
      While therapeutic dosing in countries where it is approved may range from
      25 to 50 mg/day, recreational use may be 100 times that amount.
    </p>
  </>
);

const locationsSubtitle =
  "Monitor and compare trends in tianeptine and the tianeptine MC5 metabolite within locations. Toggle axis standardization for ease of comparison across locations.";

const text: LCMSCommandCenterText = {
  summaryCard: {
    subtitle: summarySubtitle,
    aboutTheData,
  },
  locationsCard: {
    subtitle: locationsSubtitle,
  },
};

const TianeptinePage = () => {
  const { data, isLoading } = api.data.tianeptineWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default TianeptinePage;
