import { merge } from "lodash";

import { ChartTheme } from "../bb-chart";
import colors from "../colors";

const themeColors = [
  colors["biobotblue-6"],
  colors["lime-4"],
  colors["yellow-3"],
  colors["aqua-2"],
  colors["red-5"],
  colors["coral-3"],
  colors["purple-6"],
  colors["pink-4"],
  colors["biobotblue-5"],
  colors["navy-7"],
  colors["green-5"],
  colors["yellow-5"],
  colors["aqua-6"],
  colors["orange-5"],
  colors["navy-6"],
  colors["skyblue-7"],
  colors["biobotblue-3"],
  colors["navy-5"],
];

/**
 * Edit this theme as necessary for *global chart theming*,
 * or create new custom themes to use with ChartThemeProvider on a per-component basis
 */
export const chartTheme: ChartTheme = {
  axis: {
    tickClassName: "text-xs text-neutral-4",
    tickLineProps: { className: "stroke-neutral-2" },
    axisLineClassName: "stroke-neutral-2",
    gridLineClassName: "stroke-neutral-2 stroke-dashed",
  },
  tooltip: {
    className:
      "font-mono text-navy-9 text-xs bg-neutral-1 opacity-95 rounded shadow-sm border border-solid border-navy-2 p-2",
  },
  hover: {
    line: {
      className: "stroke-neutral-2",
    },
    point: {
      className: "stroke-2 stroke-white",
      r: 4,
    },
  },
  series: {
    line: {
      className: "stroke-2",
    },
  },
  colors: themeColors,
};

// We only ever deal with sample _dates_, never timestamps, so doing the gap
// calculation in milliseconds is fine, since js always parses dates without
// times in UTC (which does not have daylight savings time). If we needed to
// handle timestamps, we'd want to use dayjs to do the date diff, which is a
// lot slower this simple math.
const millisecondsPerDay = 86400000;

export const compareLocationsChartTheme: ChartTheme = {
  ...chartTheme,
  series: {
    line: {
      gap: 40 * millisecondsPerDay,
      unfocusedClassName: "opacity-20 saturate-50",
      className: "stroke-2",
    },
  },
};

export const smallChartTheme: ChartTheme = merge({}, chartTheme, {
  colors: [colors["biobotblue-6"], colors["skyblue-8"], ...themeColors],
  series: {
    line: {
      gap: 40 * millisecondsPerDay,
    },
  },
});
