import { Checkbox, Table } from "antd";
import { useState } from "react";

import api, { type ApiData } from "src/api";
import { useTableQueryParams } from "src/hooks/useTableQueryParams";
import { renderTimestampLocal, renderTimestampUTC } from "src/util/table";

import IngestDetail from "./IngestDetail";

type AuditLogRow = ApiData<"audit.load.list">[number];

const userTZ = (() => {
  try {
    return Intl.DateTimeFormat("en-us", { timeZoneName: "shortGeneric" })
      .format(new Date())
      .match(/\S+$/);
  } catch (e) {
    return "local time";
  }
})();

const IngestLogPage = () => {
  const { queryParams, handleTableChange, pagination } =
    useTableQueryParams<AuditLogRow>({
      defaultSort: "start_time:desc",
    });
  const { data: tableData, isLoading } = api.audit.load.list.useQuery({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(queryParams as any),
    _fields: [
      "key",
      "start_time",
      "end_time",
      "elapsed_ms",
      "total_changes",
      "job",
    ],
  });
  const [useUTC, setUseUTC] = useState(false);
  const [selectedKey, setSelectedKey] = useState<number | null>(null);
  return (
    <div>
      <h1 className="text-h1">Ingest Log</h1>
      <Checkbox
        checked={useUTC}
        onChange={(e) => setUseUTC(e.target.checked)}
        data-test="ingestlog-times"
      >
        Show times in UTC
      </Checkbox>
      <Table
        loading={isLoading}
        showSorterTooltip
        dataSource={tableData?.data}
        rowKey="key"
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        pagination={pagination}
        columns={[
          { title: "Job Name", dataIndex: "job" },
          {
            title: `Start time (${useUTC ? "UTC" : userTZ})`,
            dataIndex: "start_time",
            sorter: true,
            render: useUTC ? renderTimestampUTC : renderTimestampLocal,
          },
          {
            title: `End time (${useUTC ? "UTC" : userTZ})`,
            dataIndex: "end_time",
            sorter: true,
            render: useUTC ? renderTimestampUTC : renderTimestampLocal,
          },
          { title: "Load time (ms)", dataIndex: "elapsed_ms", sorter: true },
          {
            title: "Total rows changed",
            dataIndex: "total_changes",
            sorter: true,
          },
        ]}
        rowSelection={{
          type: "radio",
          onChange: (_, rows) => setSelectedKey(rows[0]?.key),
          selectedRowKeys: selectedKey ? [selectedKey] : [],
        }}
      />
      {selectedKey && <IngestDetail logKey={selectedKey} />}
    </div>
  );
};

export default IngestLogPage;
