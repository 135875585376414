import { Form, Tag } from "antd";

import BigSelect from "src/components/BigSelect";
import DateStringPicker from "src/components/DateStringPicker";
import Help from "src/components/Help";

import type {
  DataFilter,
  DataFilterComponent,
  DataFilterComponentProps,
} from "./types";
import { formGridClass, formSelectProps, validateEndDate } from "./utils";

export const validateKitDataFilter = (
  dataFilter: Extract<DataFilter, { filter_type: "kit" }>,
) => {
  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const isValid =
    dataFilter?.organization_display_id?.length ||
    dataFilter?.sampling_location_id?.length ||
    dataFilter?.program_code?.length;
  /* eslint-enable */
  if (isValid) {
    return Promise.resolve();
  } else {
    return Promise.reject(
      new Error(
        "You must select at least one program/organization/location filter",
      ),
    );
  }
};

const KitDataFilterHelp = () => (
  <div className="space-y-4 [&>*]:space-y-3 text-navy-9">
    <div>
      <p className="text-h3">Datasets (required)</p>
      <p>
        Select the datasets that the customer should have available to them. For
        chemistry data, we have several shortcuts that select different chem
        bundles. You are encouraged to use these:
        <ul>
          <li>
            <Tag className="text-xs mx-1">All hrs datasets</Tag>
          </li>
          <li>
            <Tag className="text-xs mx-1">All sud datasets</Tag>
          </li>
          <li>
            <Tag className="text-xs mx-1">All opioid datasets</Tag>
          </li>
        </ul>
      </p>
      <p>
        Note that the dataset selection determines which command centers are
        available in the sidebar.
      </p>
    </div>
    <div>
      <p className="text-h3">Location selection (required)</p>
      <p>
        You should usually configure a customer using one of programs,
        organizations, or sampling locations. You do not need to select a
        program and an organization and a location. You can pick more than one
        option per category, e.g. picking multiple organizations will show data
        from all selected organizations.
      </p>
      <p>
        In rare circumstances you may want to select a program and a location,
        or a program and an organization. This would display data produced for
        the program at the selected locations (or organizations). There is never
        a need to select both organizations and locations, since that would show
        data for locations that exist in a that organization, and locations
        already only exist in a single location.
      </p>
      <p>
        If you need to show all data for an organization{" "}
        <span className="text-bold">and</span> data for an extra location in a
        different organization, you can add multiple kit data slices: one for
        the organization, and a second one for the extra location.
        Alternatively, you could add one data slice with the full list of
        locations and no organization selection.
      </p>
    </div>
    <div>
      <p className="text-h3">Date range (optional)</p>
      <p>
        You may additionally filter data by data range. Both start and end dates
        are optional, so you do not need to set the end date to a distant future
        date like 4000-12-31 or 9999-12-31.
      </p>
    </div>
  </div>
);

const KitDataFilter: DataFilterComponent<"kit"> = ({
  name,
  options,
}: DataFilterComponentProps) => (
  <div className={formGridClass}>
    <p className="leading-8">
      <span className="text-coral-5">* </span>Datasets{" "}
      <Help title="Kit Data Help">
        <KitDataFilterHelp />
      </Help>
    </p>
    <Form.Item name={[...name, "dataset_name"]} rules={[{ required: true }]}>
      <BigSelect options={options.dataset_name.kit} {...formSelectProps} />
    </Form.Item>
    <div className="col-span-2 leading-6">
      <p>
        <span className="text-coral-5">* </span>Location selection
      </p>
      <p className="text-navy-5 w-0 min-w-full">
        Choose at least one program, organization, or sampling location (you do
        not need to pick all three)
      </p>
    </div>
    <p className="ml-8 leading-8">Programs</p>
    <Form.Item name={[...name, "program_code"]}>
      <BigSelect options={options.program_code} {...formSelectProps} />
    </Form.Item>
    <p className="ml-8 leading-8">Organizations</p>
    <Form.Item name={[...name, "organization_display_id"]}>
      <BigSelect
        options={options.organization_display_id}
        {...formSelectProps}
      />
    </Form.Item>
    <p className="ml-8 leading-8">Sampling locations</p>
    <Form.Item
      name={[...name, "sampling_location_id"]}
      rules={[
        ({ getFieldValue }) => ({
          validator: () =>
            validateKitDataFilter(getFieldValue(["data_filter", ...name])),
        }),
      ]}
      dependencies={[
        // hmm this doesn't work?
        ["data_filter", ...name, "program_code"],
        ["data_filter", ...name, "organization_display_id"],
      ]}
    >
      <BigSelect options={options.sampling_location_id} {...formSelectProps} />
    </Form.Item>
    <p className="leading-8">Sample date start</p>
    <Form.Item name={[...name, "start_date"]}>
      <DateStringPicker />
    </Form.Item>
    <p className="leading-8">Sample date end</p>
    <Form.Item
      name={[...name, "end_date"]}
      rules={[{ validator: (_, val) => validateEndDate(val) }]}
    >
      <DateStringPicker />
    </Form.Item>
  </div>
);

KitDataFilter.defaultFilter = {
  filter_type: "kit",
  dataset_name: ["*kit"],
};

export default KitDataFilter;
