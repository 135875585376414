import { Button, Tabs } from "antd";
import Link from "antd/es/typography/Link";
import React, { useState } from "react";

import api from "src/api";
import BiobotCard from "src/components/BiobotCard";
import EmptyPlot from "src/components/EmptyPlot";
import LinkIcon from "src/components/ExternalLinkIcon";

import TrendsTable from "./TrendsTable";

const NoDataDescription = ({ assayText }: { assayText: React.ReactNode }) => (
  <>
    To add <span className="text-biobotblue-5 break-keep"> {assayText} </span>
    data & insights, contact us at{" "}
    <Link
      className="text-h3 underline"
      href={`mailto:support@biobot.io?subject=Unlock ${assayText} Data`}
      target="_blank"
    >
      support@biobot.io
    </Link>
    .
  </>
);

const NoroInDevDescription = () => (
  <>
    Our
    <span className="text-biobotblue-5 break-keep"> Norovirus </span>
    insights feature is still in R&D, if you’d like to be on the waitlist for
    this please contact us at{" "}
    <Link
      className="text-h3 underline"
      href="mailto:support@biobot.io?subject=Unlock Norovirus Trends Data"
      target="_blank"
    >
      support@biobot.io
    </Link>
    .
  </>
);

const assayDetails = {
  covid: {
    text: <>SARS&#8209;CoV&#8209;2</>,
    value: "covid",
  },
  norovirus: {
    text: "Norovirus",
    value: "norovirus",
  },
} as const;

const tabItems = [
  // these are antd fields for the tabs component
  {
    label: "SARS-CoV-2",
    key: "covid",
  },
  {
    label: "NOROVIRUS",
    key: "norovirus",
  },
];

const useAssayQuery = (assayName: "covid" | "norovirus") => {
  const covidQuery = api.data.covidWastewater.useQuery(
    {
      _fields: [
        "sampling_location_id",
        "sample_date",
        "effective_concentration",
      ],
      _limit: 1,
    },
    { enabled: assayName === "covid" },
  );
  const noroQuery = api.data.norovirusWastewater.useQuery(
    {
      _fields: [
        "sampling_location_id",
        "sample_date",
        "effective_concentration",
      ],
      _limit: 1,
    },
    { enabled: assayName === "norovirus" },
  );
  return assayName === "covid" ? covidQuery : noroQuery;
};

const Watchlist = () => {
  const [assay, setAssay] = useState<
    (typeof assayDetails)["covid" | "norovirus"]
  >(assayDetails.covid);
  const { data, isLoading } = useAssayQuery(assay.value);

  return (
    <BiobotCard
      title={
        <div className="flex justify-between">
          Watchlist
          <Button
            href="https://h4pqzlzlu3r.typeform.com/to/A82UFyYu"
            target="_blank"
            data-test="beta-trends-feedback"
            rel="noreferrer"
            title="Visit Biobot's Feedback Survey"
            className="text-navy-7"
          >
            <span>Beta Trends Feedback</span>
            <LinkIcon className="align-sub pl-2" />
          </Button>
        </div>
      }
    >
      <Tabs
        defaultActiveKey="SARS-CoV-2"
        items={tabItems}
        className="font-medium"
        onTabClick={(key) => {
          setAssay(assayDetails?.[key]);
        }}
      />
      <div className="text-h3 font-light mt-1 mb-1" data-test="watchlist-table">
        {isLoading && <EmptyPlot status="loading" />}
        {!isLoading && data?.data?.length ? (
          <div>
            {assay.value === "norovirus" ? (
              <NoroInDevDescription />
            ) : (
              <TrendsTable assay={assay} />
            )}
          </div>
        ) : (
          <NoDataDescription assayText={assay.text} />
        )}
      </div>
    </BiobotCard>
  );
};

export default Watchlist;
