import { KeyOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd";

import ButtonLink from "src/components/ButtonLink";
import PageTitle from "src/components/PageTitle";
import { useRoleCheck } from "src/util/hooks";

const buttonProps: ButtonProps = {
  block: true,
  size: "large",
  className: "h-fit text-left p-4 text-2xl",
};

const UserSettingsPage = () => {
  const canManageApiTokens = useRoleCheck(["biobot-admin", "api-token-user"]);

  return (
    <div>
      <PageTitle.Heading>User Settings</PageTitle.Heading>
      {canManageApiTokens && (
        <ButtonLink
          to="/settings/tokens"
          icon={<KeyOutlined />}
          {...buttonProps}
        >
          Manage API Tokens
        </ButtonLink>
      )}
    </div>
  );
};

export default UserSettingsPage;
