import { useId } from "src/bb-chart/util";
import BiobotCard from "src/components/BiobotCard";

import LCMSLocationsBody from "./LCMSLocationsBody";
import { locationsCardSubtitle, locationsCardTitle } from "./text";
import type { Compound, LCMSDatum } from "./util";

export interface LCMSLocationsText {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

export interface LCMSLocationsCardProps extends LCMSLocationsText {
  data: LCMSDatum[];
  compounds: readonly Compound[];
}

const LCMSLocationsCard = ({
  data,
  compounds,
  title,
  subtitle,
}: LCMSLocationsCardProps) => {
  const titleId = useId();
  const subtitleId = useId();
  return (
    <BiobotCard
      title={title ?? locationsCardTitle(compounds)}
      subtitle={subtitle ?? locationsCardSubtitle(compounds)}
      titleId={titleId}
      subtitleId={subtitleId}
    >
      <LCMSLocationsBody
        data={data}
        compounds={compounds}
        aria-labelledby={titleId}
        aria-describedby={subtitleId}
      />
    </BiobotCard>
  );
};

export default LCMSLocationsCard;
