import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [{ label: "Hydromorphone", value: "Hydromorphone" }];

const aboutTheData = (
  <>
    <p>
      Hydromorphone is a semi-synthetic opioid used to treat moderate to severe
      pain. It is available in several forms, including oral tablets,
      injections, and suppositories. Life-threatening overdoses are possible,
      and prolonged has high potential for physical dependence. Hydromorphone is
      excreted in urine and moderately stable in wastewater.
    </p>
    <p>
      Medical doses range from 2 mg to 4 mg per administration, depending on the
      severity of the pain and patient tolerance.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const HydromorphonePage = () => {
  const { data, isLoading } = api.data.hydromorphoneWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default HydromorphonePage;
