import { ParentSize } from "@visx/responsive";
import { createContext, useContext, useMemo } from "react";

export interface Margin {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export interface SizeProps {
  width?: number;
  height?: number;
  margin: Margin;
}

export interface ChartSize {
  svgWidth: number;
  svgHeight: number;
  width: number;
  height: number;
  margin: Margin;
}

const ChartSizeContext = createContext<ChartSize | undefined>(undefined);

export const useChartSize = () => {
  const value = useContext(ChartSizeContext);
  if (!value) {
    throw new Error("useChartSize must be called inside ChartSizeProvider");
  }
  return value;
};

export interface ChartSizeProviderProps {
  width?: number;
  height?: number;
  margin: Margin;
  children: React.ReactNode;
}

const ChartSizeProviderInner = ({
  width: svgWidth,
  height: svgHeight,
  margin,
  children,
}: Required<ChartSizeProviderProps>) => {
  const { top, left, right, bottom } = margin;
  const value = useMemo(() => {
    const chartWidth = Math.max(0, svgWidth - left - right);
    const chartHeight = Math.max(0, svgHeight - top - bottom);
    return {
      svgWidth,
      svgHeight,
      width: chartWidth,
      height: chartHeight,
      margin: { top, left, right, bottom },
    };
  }, [svgWidth, svgHeight, top, left, right, bottom]);
  if (value.width === 0 || value.height === 0) {
    return null;
  }
  return (
    <ChartSizeContext.Provider value={value}>
      {children}
    </ChartSizeContext.Provider>
  );
};

/**
 * Provider for chart dimensions. This is typically the outermost chart
 * context. If width and height are not given, size is calculated based on the
 * parent size.
 */
export const ChartSizeProvider = ({
  width,
  height,
  margin,
  children,
}: ChartSizeProviderProps) => {
  if (width != null && height != null) {
    return (
      <ChartSizeProviderInner width={width} height={height} margin={margin}>
        {children}
      </ChartSizeProviderInner>
    );
  }
  return (
    <ParentSize debounceTime={100}>
      {({ width: parentWidth, height: parentHeight }) => (
        <ChartSizeProviderInner
          width={width ?? parentWidth}
          height={height ?? parentHeight}
          margin={margin}
        >
          {children}
        </ChartSizeProviderInner>
      )}
    </ParentSize>
  );
};
