import cx from "classnames";
import { capitalize, find } from "lodash";

import api from "src/api";

const colorMap = {
  low: "bg-green-2",
  moderate: "bg-yellow-2",
  high: "bg-coral-2",
} as const;

interface ContainerProps {
  color: string;
  children: React.ReactNode;
}
const Container = ({ color, children }: ContainerProps) => {
  const baseClasses = `absolute inset-0 flex justify-center items-center rounded drop-shadow-lg opacity-90 text-xl font-light`;

  return (
    <div className={cx(baseClasses, color)}>
      <div>{children}</div>
    </div>
  );
};

interface RiskLevelOverlayProps {
  sampling_location_name: string;
}

const CovidRiskLevelOverlay = ({
  sampling_location_name,
}: RiskLevelOverlayProps) => {
  // TODO: pass this in from above instead of hitting the api here
  const {
    data: riskLevel,
    isLoading,
    isError,
  } = api.data.covidRiskLevel.useQuery(
    { _fields: ["sampling_location_name", "risk_level"] },
    {
      select: (data) =>
        find(data?.data, ["sampling_location_name", sampling_location_name])
          ?.risk_level,
    },
  );

  if (isLoading) {
    return null;
  } else if (riskLevel == null || isError) {
    return <Container color="bg-neutral-2">Insufficient Data</Container>;
  } else {
    return (
      <Container color={colorMap[riskLevel]}>
        Current Risk:{" "}
        <span className="font-medium">{capitalize(riskLevel)}</span>
      </Container>
    );
  }
};

export default CovidRiskLevelOverlay;
