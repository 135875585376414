import covidImg from "../../images/covid_virus.png";
import AssayCard from "./AssayCard";
import { ExportButton } from "./Buttons";
import { Dataset, LinkComponentProps, useLinkComponent } from "./links";

const datasets = [
  "covidNWSS",
  "covidCDC",
  "covidKits",
  "covidCountyInterpolated",
  "covidCountyAverage",
  "covidStateAverage",
  "covidRegionalAverage",
  "covidNationalAverage",
] satisfies Dataset[];

interface CovidDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const CovidDownloads = ({ className, ...props }: CovidDownloadsProps) => {
  const { state, links } = useLinkComponent({ ...props, datasets });
  return (
    <div className={className}>
      <AssayCard
        disabled={state === "no-data" || state === "loading"}
        title="SARS-CoV-2"
        image={covidImg}
        className="mb-5"
      />
      {state === "loading" && <ExportButton label="loading..." loading />}
      {state === "data" && (
        <>
          <ExportButton
            label="NWSS+"
            href={links.covidNWSS}
            gaAction="covidNWSS-downloaded"
          />
          <ExportButton
            label="CDC NWSS+"
            href={links.covidCDC}
            gaAction="covidCDC-downloaded"
          />
          <ExportButton
            label="CSV"
            href={links.covidKits}
            gaAction="covidKits-downloaded"
          />
          <ExportButton
            label="County AI Subscription"
            href={links.covidCountyInterpolated}
            gaAction="covidCountyInterpolated-downloaded"
          />
          <ExportButton
            label="County Subscription"
            href={links.covidCountyAverage}
            gaAction="covidCountyAverage-downloaded"
          />
          <ExportButton
            label="State Subscription"
            href={links.covidStateAverage}
            gaAction="covidStateAverage-downloaded"
          />
          <ExportButton
            label="Regional Subscription"
            href={links.covidRegionalAverage}
            gaAction="covidRegionalAverage-downloaded"
          />
          <ExportButton
            label="National Subscription"
            href={links.covidNationalAverage}
            gaAction="covidNationalAverage-downloaded"
          />
        </>
      )}
    </div>
  );
};

export default CovidDownloads;
