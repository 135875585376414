import hrsImg from "src/images/hrs_molecule.png";

import AssayCard from "./AssayCard";
import { ExportButton } from "./Buttons";
import { Dataset, LinkComponentProps, useLinkComponent } from "./links";

// TODO: lcmsKits
const datasets = ["hrsKits", "lcmsNationalAverage"] satisfies Dataset[];

interface SubstancesDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const SubstancesDownloads = ({
  className,
  ...props
}: SubstancesDownloadsProps) => {
  const { state, links } = useLinkComponent({ ...props, datasets });
  return (
    <div className={className}>
      <AssayCard
        disabled={state === "no-data" || state === "loading"}
        title="Substances"
        singularTitle="Substance"
        image={hrsImg}
        className="mb-5"
      />
      {state === "loading" && <ExportButton label="loading..." loading />}
      {state === "data" && (
        <>
          <ExportButton
            label="CSV"
            href={links.hrsKits}
            gaAction="hrsKits-downloaded"
          />
          <ExportButton
            label="National Subscription"
            href={links.lcmsNationalAverage}
            gaAction="lcmsNationalAverage-downloaded"
          />
        </>
      )}
    </div>
  );
};

export default SubstancesDownloads;
