import { every, isUndefined } from "lodash";
import { useEffect } from "react";
import {
  ArrayParam as ArrayParamBase,
  BooleanParam as BooleanParamBase,
  NumericArrayParam as NumericArrayParamBase,
  QueryParamConfig,
  QueryParamConfigMap,
  StringParam as StringParamBase,
  useQueryParams,
  withDefault,
} from "use-query-params";

/** Like ArrayParam from use-query-params, but excluding nulls */
export const ArrayParam = withDefault(
  ArrayParamBase,
  undefined,
) as QueryParamConfig<string[] | undefined>;

/** Like NumericArrayParam from use-query-params, but excluding nulls */
export const NumericArrayParam = withDefault(
  NumericArrayParamBase,
  undefined,
) as QueryParamConfig<number[] | undefined>;

/** Like BooleanParam from use-query-params, but excluding nulls */
export const BooleanParam = withDefault(
  BooleanParamBase,
  undefined,
) as QueryParamConfig<boolean | undefined>;

/** Like StringParam from use-query-params, but excluding nulls */
export const StringParam = withDefault(
  StringParamBase,
  undefined,
) as QueryParamConfig<string | undefined>;

/**
 * Wrapper around useQueryParams that perists params when a navigation event
 * occurs without query params.
 */
export const useSessionQueryParams = <TConfig extends QueryParamConfigMap>(
  config: TConfig,
  defaults: { [K in keyof TConfig]: TConfig[K]["default"] },
) => {
  const storageKey = `sticky-${Object.keys(config).sort().join("-")}:`;
  const [params, setParams] = useQueryParams(config);

  // If the search string is cleared, assume this is from a navigation event
  // that does not include query params. In that case, we want to reset to the
  // stored values (or defaults if there are none).
  let replaceParams: typeof params | null = null;
  if (every(params, isUndefined)) {
    const savedValues = sessionStorage.getItem(storageKey);
    replaceParams = savedValues ? JSON.parse(savedValues) : defaults;
    // break a potential infinite loop where savedValues is an empty object
    if (every(replaceParams, isUndefined)) {
      replaceParams = defaults;
    }
  }

  // Persist params in the query string and in sessionStorage any time they
  // change.
  useEffect(() => {
    if (replaceParams) {
      setParams(replaceParams, "replace");
    }
    sessionStorage.setItem(storageKey, JSON.stringify(replaceParams ?? params));
  }, [params, replaceParams, setParams, storageKey]);

  // If we have replaceParams, it's because params are invalid. While we did
  // just reset params to be replaceParams, that isn't going to take effect
  // until the next render. We return replaceParams immediately so that the
  // caller has a valid value at all times.
  return [replaceParams ?? params, setParams] as const;
};
