import {
  BulbOutlined,
  ControlOutlined,
  DownloadOutlined,
  FlagOutlined,
  HomeOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import { kebabCase, startCase } from "lodash";
import { CSSProperties } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Customer,
  useCustomer,
  useCustomerOverride,
} from "src/context/Customer";
import { useFeatureFlags } from "src/context/FeatureFlag";
import { ROUTES } from "src/routes/routeMap";
import { RoleName, useRoles } from "src/util/hooks";

import InfectiousDiseaseIcon from "../InfectiousDiseaseIcon";
import SubstanceIcon from "../SubstancesIcon";

type MenuItem = Required<MenuProps>["items"][number];

type CustomerDataset = NonNullable<Customer["datasets"]>[number];

interface MkItemProps {
  label: string;
  labelClassName?: string;
  path?: string;
  icon?: React.ReactNode;
  children?: (MenuItem | false | undefined | null)[];
  type?: "group";
  visible?: boolean;
}

const mkItem = ({
  label,
  labelClassName,
  path,
  icon,
  children: children_,
  type,
  visible = true,
}: MkItemProps): MenuItem => {
  const children = children_?.filter(Boolean);
  if (!visible) {
    return null;
  } else if (children && children.length === 0) {
    return null;
  }
  return {
    key: path || kebabCase(label),
    icon,
    children,
    label: (
      <span className={labelClassName}>
        {path ? <Link to={path}>{label}</Link> : label}
      </span>
    ),
    type,
    style: { listStyle: "none" } as CSSProperties,
  };
};

const NavMenu = ({ collapsed }: { collapsed: boolean }) => {
  const { pathname } = useLocation();
  const roles = useRoles();
  const { customerMode } = useCustomerOverride();
  const { datasets } = useCustomer();
  const flags = useFeatureFlags();

  const hasRole = (role: RoleName) => {
    if (customerMode) {
      return false;
    }
    return roles.has(role);
  };

  const hasDataset = (dataset: CustomerDataset) => {
    if (!flags.hideUnavailableCommandCenters) {
      return true;
    }
    return datasets?.includes(dataset);
  };

  // Ant calculates indents for regular menu items as level * 24px, but it does
  // not do the same for group items! Group items have a hard-coded 32px
  // indent, and regular non-group items additionally have a 4px margin that
  // group items don't. Our goal is to end up with a level 2 indent, i.e. 48px,
  // meaning we need to add 48 + 4 - 32 = 20.
  const level2GroupClass = collapsed ? undefined : "pl-[20px] block";

  const commandCenter = (
    dataset: CustomerDataset & keyof typeof ROUTES,
    label?: string,
  ) =>
    mkItem({
      label: label ?? startCase(dataset),
      path: ROUTES[dataset],
      visible: hasDataset(dataset),
    });

  const items = [
    mkItem({
      label: "Homepage",
      path: ROUTES.homepage,
      icon: <HomeOutlined className="text-xl" />,
    }),
    mkItem({
      label: "Download Hub",
      path: ROUTES.download_hub,
      icon: <DownloadOutlined className="text-xl" />,
    }),
    mkItem({
      label: "Infectious Disease",
      icon: <InfectiousDiseaseIcon className="w-5" />,
      children: [
        mkItem({
          label: "Respiratory",
          children: [
            commandCenter("covid", "SARS-CoV-2"),
            flags.fluRsvPagesUi && commandCenter("rsv", "RSV"),
            flags.fluRsvPagesUi && commandCenter("flu", "Influenza"),
          ],
        }),
        mkItem({
          label: "Gastrointestinal",
          children: [commandCenter("norovirus", "Norovirus")],
        }),
      ],
    }),
    mkItem({
      visible: flags.substancesPagesUi,
      label: "Substances",
      icon: <SubstanceIcon className="w-5" />,
      children: [
        mkItem({
          label: "High-Risk Substances",
          children: [
            commandCenter("cocaine"),
            commandCenter("fentanyl"),
            commandCenter("methamphetamine"),
            commandCenter("nicotine"),
            commandCenter("xylazine"),
          ],
        }),
        mkItem({
          visible: flags.sudPanelUi,
          label: "Opioids",
          children: [
            mkItem({
              label: "Illicit Opioids & Adjacent Substances",
              labelClassName: level2GroupClass,
              type: "group",
              children: [
                commandCenter("acetylfentanyl", "Acetyl fentanyl"),
                commandCenter("carfentanil"),
                commandCenter("heroin"),
                commandCenter("sufentanil"),
                commandCenter("tianeptine"),
              ],
            }),
            mkItem({
              label: "Rx Opioids",
              labelClassName: level2GroupClass,
              type: "group",
              children: [
                commandCenter("codeine"),
                commandCenter("dihydrocodeine"),
                commandCenter("hydrocodone"),
                commandCenter("hydromorphone"),
                commandCenter("morphine"),
                commandCenter("oxycodone"),
                commandCenter("oxymorphone"),
                commandCenter("tramadol"),
              ],
            }),
          ],
        }),
        mkItem({
          label: "SUD Treatment & Intervention",
          children: [
            flags.sudPanelUi && commandCenter("buprenorphine"),
            flags.sudPanelUi && commandCenter("methadone"),
            commandCenter("naloxone"),
          ],
        }),
      ],
    }),
    mkItem({
      visible: hasRole("biobot-admin"),
      label: "Customer Config",
      path: ROUTES.customer_config,
      icon: <ControlOutlined className="text-xl" />,
    }),
    mkItem({
      visible: hasRole("biobot-admin"),
      label: "Notification Log",
      path: ROUTES.notification_log,
      icon: <MailOutlined className="text-xl" />,
    }),
    mkItem({
      visible: hasRole("biobot-admin"),
      label: "Ingest Log",
      path: ROUTES.ingest_log,
      icon: <BulbOutlined className="text-xl" />,
    }),
    mkItem({
      visible: hasRole("biobot-feature-flagger"),
      label: "Feature Flags",
      path: ROUTES.feature_flags,
      icon: <FlagOutlined className="text-xl" />,
    }),
  ];

  return (
    <Menu
      mode="inline"
      className="menu-transparent py-4 border-0"
      selectedKeys={[pathname]}
      items={items.filter(Boolean)}
      defaultOpenKeys={collapsed ? [] : ["infectious-disease", "substances"]}
    />
  );
};

export default NavMenu;
