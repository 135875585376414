import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Dropdown, MenuProps } from "antd";
import { Link } from "react-router-dom";

import { useRoleCheck } from "src/util/hooks";

const HeaderDropdownMenu = ({ className }: { className: string }) => {
  const { logout } = useAuth0();
  const canManageApiTokens = useRoleCheck(["biobot-admin", "api-token-user"]);

  const headerMenuItems: MenuProps["items"] = [
    canManageApiTokens && {
      key: "settings",
      label: <Link to="/settings">Settings</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: "log-out",
      label: "Log Out",
      icon: <LogoutOutlined />,
      onClick() {
        sessionStorage.clear();
        logout({ returnTo: window.location.origin });
      },
    },
  ].filter(Boolean);

  return (
    <Dropdown
      className={className}
      data-test="settings"
      trigger={["click"]}
      menu={{ items: headerMenuItems }}
    >
      <SettingOutlined className="text-2xl" />
    </Dropdown>
  );
};

export default HeaderDropdownMenu;
