import FAQs from "./components/FAQs";
import Watchlist from "./components/Watchlist";

const Homepage = () => (
  <div className="mb-8">
    <Watchlist />
    <FAQs className="mt-6" />
  </div>
);
export default Homepage;
