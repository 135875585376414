import { ConfigProvider } from "antd";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import queryClient from "./api/queryClient";
import { ChartThemeProvider } from "./bb-chart";
import LagRadar from "./components/LagRadar";
import { HelpProvider } from "./context/Help";
import Auth0RouteProvider from "./routes/Auth0RouteProvider";
import DashboardRoutes from "./routes/Routes";
import { chartTheme } from "./theme/chartTheme";
import { themeConfig } from "./theme/themeConfig";

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Auth0RouteProvider>
          <ConfigProvider theme={themeConfig}>
            <ChartThemeProvider value={chartTheme}>
              <HelpProvider>
                <DashboardRoutes />
              </HelpProvider>
            </ChartThemeProvider>
          </ConfigProvider>
        </Auth0RouteProvider>
      </QueryParamProvider>
    </Router>
    <ReactQueryDevtools initialIsOpen={false} />
    <LagRadar />
  </QueryClientProvider>
);

export default App;
