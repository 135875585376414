import type { TableProps } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import { isNil, omitBy } from "lodash";
import { useCallback, useState } from "react";

import useDeepCompareMemo from "./useDeepCompareMemo";

type ChangeParams<Row> = Parameters<
  Exclude<TableProps<Row>["onChange"], undefined>
>;
type Pagination<Row> = ChangeParams<Row>[0];
type Filters<Row> = ChangeParams<Row>[1];
type Sorter<Row> = ChangeParams<Row>[2];
interface AntTableState<Row> {
  pagination: Pagination<Row>;
  filters: Filters<Row>;
  sorter: Sorter<Row>;
}

type Op = "" | "_gt" | "_gte" | "_lt" | "_lte";

type Sort = `${string}:asc` | `${string}:desc`;

export interface QueryParams<Row extends object> {
  _sort?: Sort;
  _where?: { [K in keyof Row & string as `${K}${Op}`]?: Row[K] | Row[K][] };
  _start?: number;
  _limit?: number;
}

const dataIndexSortKey = (dataIndex: unknown) =>
  Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex;

const tableSortParam = <Row>({
  order,
  column,
}: SorterResult<Row>): Sort | undefined => {
  if (column?.dataIndex && order) {
    const field = dataIndexSortKey(column.dataIndex);
    if (order === "ascend") {
      return `${field}:asc`;
    } else {
      return `${field}:desc`;
    }
  }
  return undefined;
};

export const tableQueryParams = <Row extends object>(
  pagination: Pagination<Row>,
  filters: QueryParams<Row>["_where"],
  sorter: Sorter<Row>,
): QueryParams<Row> => {
  const pageSize: number =
    pagination.pageSize ?? pagination.defaultPageSize ?? 10;
  const currentPage: number = pagination.current ?? 1;
  const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;
  const cleanFilters = omitBy(filters, isNil) as QueryParams<Row>["_where"];
  const queryParams: QueryParams<Row> = {
    _where: cleanFilters ?? {},
    _sort: tableSortParam(singleSorter ?? {}),
    _start: (currentPage - 1) * pageSize,
    _limit: pageSize,
  };
  return omitBy(queryParams, isNil);
};

/**
 * Hook to manage table filters, pagination, and sorting.
 *
 * @param {number} options.defaultPageSize
 * @param {object} options.permanentFilters -- filters to apply to all requests
 * @param {object} options.defaultFilters -- filters to apply by default
 * @param {string} options.defaultSort
 *
 * @example
 * const { queryParams, pagination handleTableChange } = useTableQueryParams({
 *   defaultPageSize: 50,
 *   permanentFilters: { organization: 20 }
 *   defaultSort: "my_field:DESC",
 * });
 * const { data, isLoading } = useSomeApiList(queryParams);
 * return (
 *   <Table
 *     onChange={handleTableChange}
 *     loading={isLoading}
 *     dataSource={data?.data}
 *     pagination={{ ...pagination, total: data?.count }}
 *   />
 * )
 */
export const useTableQueryParams = <Row extends object>({
  defaultPageSize = 10,
  permanentFilters = {},
  defaultFilters = {},
  defaultSort,
}: {
  defaultPageSize?: number;
  permanentFilters?: QueryParams<Row>["_where"];
  defaultFilters?: QueryParams<Row>["_where"];
  defaultSort?: QueryParams<Row>["_sort"];
} = {}) => {
  const [antTableState, setAntTableState] = useState<AntTableState<Row> | null>(
    null,
  );
  const [userSelectedFilters, setUserSelectedFilters] =
    useState<Filters<Row>>();

  const queryParams = useDeepCompareMemo(() => {
    if (antTableState === null) {
      return {
        _sort: defaultSort,
        ...tableQueryParams(
          { defaultPageSize },
          { ...permanentFilters, ...defaultFilters },
          {},
        ),
      };
    } else {
      const { pagination, filters, sorter } = antTableState;
      return {
        _sort: defaultSort,
        ...tableQueryParams(
          pagination,
          { ...filters, ...permanentFilters },
          sorter,
        ),
      };
    }
  }, [
    antTableState,
    defaultFilters,
    defaultPageSize,
    defaultSort,
    permanentFilters,
  ]);

  const handleTableChange = useCallback(
    (
      pagination: Pagination<Row>,
      filters: Filters<Row>,
      sorter: Sorter<Row>,
    ) => {
      setAntTableState({
        pagination,
        filters,
        sorter,
      });
      setUserSelectedFilters(filters);
    },
    [setAntTableState],
  );

  return {
    handleTableChange,
    queryParams,
    pagination: { defaultPageSize },
    userSelectedFilters,
  };
};
