import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

const compounds = [
  { label: "Acetyl Fentanyl", value: "Acetyl fentanyl" },
  { label: "Acetyl Norfentanyl", value: "Acetyl norfentanyl" },
];

const aboutTheData = (
  <>
    <p>
      Acetyl fentanyl is a potent synthetic opioid and fentanyl analog. It is
      not used medically but has been found as a contaminant in illicit drug
      supplies. Acetyl fentanyl and its metabolite acetyl norfentanyl are
      excreted in urine but are excreted in low concentrations and may degrade
      quickly in wastewater. Upstream sampling locations are therefore
      recommended for monitoring acetyl fentanyl use.
    </p>
    <p>
      The lethal dose in humans has not been studied, but in animals is
      suggested to be as low as 9mg.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const AcetylfentanylPage = () => {
  const { data, isLoading } = api.data.acetylfentanylWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  return (
    <LCMSCommandCenter
      data={data?.data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default AcetylfentanylPage;
