import { Navigate, Outlet } from "react-router-dom";

import { FeatureFlags, useFeatureFlags } from "src/context/FeatureFlag";

export interface FeatureProtectedRouteProps {
  flag: keyof FeatureFlags;
}

const FeatureProtectedRoute = ({ flag }: FeatureProtectedRouteProps) => {
  const flags = useFeatureFlags();

  if (flags[flag]) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

export default FeatureProtectedRoute;
