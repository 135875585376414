import fluImage from "src/images/flu.png";

import AssayCard from "./AssayCard";
import { ExportButton } from "./Buttons";
import { Dataset, LinkComponentProps, useLinkComponent } from "./links";

const datasets = [
  "fluKits",
  "fluCountyAverage",
  "fluCountyInterpolated",
  "fluStateAverage",
  "fluRegionalAverage",
  "fluNationalAverage",
] satisfies Dataset[];

interface FluDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const FluDownloads = ({ className, ...props }: FluDownloadsProps) => {
  const { state, links } = useLinkComponent({ ...props, datasets });
  return (
    <div className={className}>
      <AssayCard
        disabled={state === "no-data" || state === "loading"}
        title="Influenza"
        image={fluImage}
        className="mb-5"
      />
      {state === "loading" && <ExportButton label="loading..." loading />}
      {state === "data" && (
        <>
          <ExportButton
            label="CSV"
            href={links.fluKits}
            gaAction="fluKits-downloaded"
          />
          <ExportButton
            label="County AI Subscription"
            href={links.fluCountyInterpolated}
            gaAction="fluCountyInterpolated-downloaded"
          />
          <ExportButton
            label="County Subscription"
            href={links.fluCountyAverage}
            gaAction="fluCountyAverage-downloaded"
          />
          <ExportButton
            label="State Subscription"
            href={links.fluStateAverage}
            gaAction="fluStateAverage-downloaded"
          />
          <ExportButton
            label="Regional Subscription"
            href={links.fluRegionalAverage}
            gaAction="fluRegionalAverage-downloaded"
          />
          <ExportButton
            label="National Subscription"
            href={links.fluNationalAverage}
            gaAction="fluNationalAverage-downloaded"
          />
        </>
      )}
    </div>
  );
};

export default FluDownloads;
