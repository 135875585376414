export type HoverHandler = (
  event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>,
) => void;

export interface HoverDetectorProps {
  x?: number;
  y?: number;
  width: number;
  height: number;
  slop?: number;
  onHover?: HoverHandler;
  onLeave?: HoverHandler;
}

/** An svg `rect` that detects hovering events. */
const HoverDetector = ({
  x = 0,
  y = 0,
  width,
  height,
  slop = 5,
  onHover,
  onLeave,
}: HoverDetectorProps) => (
  <rect
    x={x - slop}
    y={y - slop}
    width={width + 2 * slop}
    height={height + 2 * slop}
    className="fill-transparent"
    onTouchStart={onHover}
    onTouchMove={onHover}
    onMouseMove={onHover}
    onMouseLeave={onLeave}
  />
);

export default HoverDetector;
