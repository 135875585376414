interface ExternalLinkIcon {
  className?: string;
}

const LinkIcon = ({ className }: ExternalLinkIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 1024 1024"
    style={{ height: "1.4em", display: "inline-block" }}
  >
    <path
      fill="#125283"
      d="m807.024003 203.55743-189.2 23.5c-6.6.8-9.3 8.8-4.7 13.5l54.7 54.7-153.5 153.499999c-3.098671 3.129382-3.098671 8.170618 0 11.3l45.1 45.1c3.1 3.1 8.2 3.1 11.3 0l153.6-153.6 54.7 54.7c2.153492 2.152395 5.348163 2.883777 8.223606 1.882697 2.875443-1.00108 4.925194-3.558298 5.276394-6.582697l23.4-189.1c.335688-2.448049-.493384-4.912154-2.240615-6.659385-1.747231-1.74723-4.211336-2.576303-6.659385-2.240615Z"
    />
    <path
      fill="#125283"
      d="M476 211c19.882251 0 36 16.117749 36 36 0 19.88225-16.117749 36-36 36H281v461.004l462.652.047.348057-196.11488c.034926-19.683397 15.860287-35.649181 35.468489-35.932297l.595333-.003767c19.88222.03528 35.971344 16.181603 35.936121 36.063823l-.411821 232.055298c-.035238 19.858731-16.144905 35.93612-36.003668 35.93612L244.996276 816C225.11548 815.997943 209 799.880796 209 780V247c0-19.882251 16.117749-36 36-36h231Z"
    />
  </svg>
);

export default LinkIcon;
