import { useAuth0 } from "@auth0/auth0-react";

import type { RoleName as RoleName_ } from "~api/types/auth";

export type RoleName = RoleName_;

/**
 * Returns all roles attached to the auth0 token.
 *
 * Note that not all roles are included in the id token, if you want to include
 * a new role in the token, you must update
 * terraform/auth0/modules/dashboard/scripts/add_roles_to_tokens.js
 */
export const useRoles = (): Set<RoleName> => {
  const { user } = useAuth0();
  return new Set(user?.["https://api.biobot.io/roles"]);
};

/**
 * Hook to check if a user has one or more roles. Returns true if the user has
 * _any_ of the requested roles.
 */
export const useRoleCheck = (roleName: RoleName | RoleName[]) => {
  const roles = useRoles();
  if (Array.isArray(roleName)) {
    return roleName.some((r) => roles.has(r));
  }
  return roles.has(roleName);
};
