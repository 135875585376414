import { Group } from "@visx/group";
import { omit } from "lodash";
import React from "react";

import { ChartDataProvider, useChartData } from "./ChartData";
import { ChartSizeProvider, Margin, useChartSize } from "./ChartSize";
import { ChartTheme, ChartThemeProvider, useChartTheme } from "./ChartTheme";
import { AnySeries } from "./Series";
import {
  TooltipComponentForSeries,
  TooltipHover,
  TooltipHoverDetector,
  TooltipProvider,
  TooltipWrapper,
} from "./Tooltip";
import { ScaleConfig } from "./types";

export interface AriaChartProps {
  "aria-label"?: string;
  "aria-description"?: string;
  "aria-labelledby"?: string;
  "aria-describedby"?: string;
}

interface ChartBodyProps<TSeries extends AnySeries[]> extends AriaChartProps {
  className?: string;
  tooltip?: TooltipComponentForSeries<[...TSeries]>;
  empty?: React.ReactNode;
  children: React.ReactNode;
}

/** The chart svg element, handling size, margins, and an optional tooltip. */
const ChartBody = <TSeries extends AnySeries[]>({
  className,
  tooltip,
  empty,
  children,
  ...ariaProps
}: ChartBodyProps<TSeries>) => {
  const { svgWidth, svgHeight, width, height, margin } = useChartSize();
  const { tooltip: ttTheme, background } = useChartTheme();
  const TTProvider = tooltip ? TooltipProvider : React.Fragment;
  const { hasNoData } = useChartData();

  if (hasNoData) {
    return (
      <div className="relative">
        <div
          style={{ width: svgWidth, height: svgHeight }}
          className={`flex items-center justify-center ${className ?? ""}`}
        >
          {empty}
        </div>
      </div>
    );
  }

  return (
    <TTProvider>
      <div className="relative">
        <svg
          width={svgWidth}
          height={svgHeight}
          className={className}
          {...ariaProps}
        >
          <Group top={margin.top} left={margin.left}>
            {background && (
              <rect width={width} height={height} fill="none" {...background} />
            )}
            {children}
            {tooltip && (
              <>
                <TooltipHover />
                <TooltipHoverDetector width={width} height={height} />
              </>
            )}
          </Group>
        </svg>
        {tooltip && (
          <TooltipWrapper tooltip={tooltip} className={ttTheme?.className} />
        )}
      </div>
    </TTProvider>
  );
};

export interface ChartProps<
  TSeries extends AnySeries[],
  TXAxes extends ScaleConfig[],
  TYAxes extends ScaleConfig[],
> extends AriaChartProps {
  series: [...TSeries];
  xScales: readonly [...TXAxes];
  yScales: readonly [...TYAxes];
  theme?: ChartTheme;
  width?: number;
  height?: number;
  margin?: Margin;
  tooltip?: TooltipComponentForSeries<[...TSeries]>;
  children?: React.ReactNode;
  empty?: React.ReactNode;
}

const defaultMargin = ({
  xScales,
  yScales,
}: {
  xScales: readonly ScaleConfig[];
  yScales: readonly ScaleConfig[];
}) => {
  const xCount = xScales.length;
  const yCount = yScales.length;
  return {
    top: xCount > 1 ? 25 : 10,
    left: yCount > 0 ? 50 : 25,
    right: yCount > 1 ? 50 : 25,
    bottom: xCount > 0 ? 25 : 10,
  };
};

/**
 * The standard Chart component. This includes all chart context providers
 * (size, data, tooltip, theme) and renders a ChartBody child.
 *
 * @example
 * // a simple line chart with a tooltip
 * const MyChart = () => {
 *   const someData: { x: number, y: number }[] = fetchData();
 *   const series = useSeries({
 *     seriesKey: "mySeries",
 *     data: someData,
 *     x: "x",
 *     y: "y",
 *   });
 *   return (
 *     <Chart
 *       series={[series]}
 *       xScales={[{ type: "linear" }]}
 *       yScales={[{ type: "linear", nice: 5 }]}
 *       tooltip={({ series: { mySeries } }) =>
 *         mySeries ? <pre>{JSON.stringify(mySeries.datum)}</pre> : null
 *       }
 *       title="A short title"
 *       desc="This is a longer description for what we're looking at"
 *     >
 *       <XAxis />
 *       <YAxis numTicks={5} />
 *       <LineSeries seriesKey="mySeries" />
 *     </Chart>
 *   );
 * };
 */
export const Chart = <
  TSeries extends AnySeries[],
  TXAxes extends ScaleConfig[],
  TYAxes extends ScaleConfig[],
>(
  props: ChartProps<TSeries, TXAxes, TYAxes>,
) => {
  const {
    series,
    xScales,
    yScales,
    theme,
    width,
    height,
    margin,
    children,
    tooltip,
    empty,
    ...ariaProps
  } = props;
  if (theme) {
    return (
      <ChartThemeProvider value={theme}>
        <Chart {...omit(props, "theme")} />
      </ChartThemeProvider>
    );
  }
  return (
    <ChartSizeProvider
      width={width}
      height={height}
      margin={margin ?? defaultMargin(props)}
    >
      <ChartDataProvider series={series} xScale={xScales} yScale={yScales}>
        <ChartBody tooltip={tooltip} empty={empty} {...ariaProps}>
          {children}
        </ChartBody>
      </ChartDataProvider>
    </ChartSizeProvider>
  );
};
