import { useMemo } from "react";

import api from "src/api";

import LCMSCommandCenter, { LCMSCommandCenterText } from "./LCMSCommandCenter";

export const compounds = [
  { label: "Heroin", value: "Heroin" },
  { label: "6-MAM", value: "6-MAM", lowerLabel: "6-MAM" },
  { label: "Morphine", value: "Morphine" },
  { label: "Morphine-3-glucuronide", value: "Morphine-3-glucuronide" },
];

const aboutTheData = (
  <>
    <p>
      Heroin is an illicit opioid known for its intense euphoric effects, rapid
      onset, and highly addictive properties. It is typically injected, snorted,
      or smoked. Trends in heroin use are closely monitored due to its high risk
      for overdose, especially when mixed with other substances like fentanyl.
      Heroin and its major metabolites, 6-acetylmorphine (commonly known as
      6-MAM), morphine-3-glucuronide, and morphine, are excreted in urine but
      degrade quickly in wastewater, and 6-MAM metabolizes further into morphine
      (itself a parent drug) following consumption of heroin. Note that Upstream
      sampling locations are therefore recommended for monitoring heroin use.
    </p>
    <p>
      Heroin doses vary based on individual tolerance and route of
      administration. For example, common dosages of injected heroin may be
      10-15mg or more, while dosages that are snorted or smoked may range from
      20-50mg or more. However, people using heroin may be unaware of how much
      they are consuming because of the drug’s unpredictable purity in the
      illicit drug market.
    </p>
  </>
);

const text: LCMSCommandCenterText = {
  summaryCard: { aboutTheData },
};

const HeroinPage = () => {
  const heroinQuery = api.data.heroinWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });
  const morphineQuery = api.data.morphineWastewater.useQuery({
    _fields: [
      "sampling_location_name",
      "location_type",
      "compound_name",
      "sample_date",
      "estimated_consumption",
    ],
  });

  const { data, isLoading } = useMemo(
    () => ({
      data: [
        ...(heroinQuery.data?.data ?? []),
        ...(morphineQuery.data?.data ?? []),
      ],
      isLoading: heroinQuery.isLoading || morphineQuery.isLoading,
    }),
    [
      heroinQuery.data,
      heroinQuery.isLoading,
      morphineQuery.data,
      morphineQuery.isLoading,
    ],
  );

  return (
    <LCMSCommandCenter
      data={data}
      isLoading={isLoading}
      compounds={compounds}
      text={text}
    />
  );
};

export default HeroinPage;
