import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Alert, Button, Layout, Space, Typography, message } from "antd";
import cx from "classnames";
import { CSSProperties, useCallback, useEffect, useState } from "react";

import {
  useCustomerOverride,
  useSetCustomerOverride,
} from "src/context/Customer";
import useIntersectionObserver from "src/hooks/useIntersectionObserver";
import { useRoleCheck } from "src/util/hooks";

import Logo from "../Logo";
import CustomerOverridePicker from "./CustomerOverridePicker";
import HeaderDropdownMenu from "./HeaderDropdownMenu";
import NavMenu from "./NavMenu";
import background from "./background.jpeg";

const { Header, Sider, Content, Footer } = Layout;
const { Link } = Typography;

const showAdminMessage = ({ customerMode }) => {
  if (customerMode) {
    message.destroy("admin-mode");
    message.info({
      content:
        "You are in customer mode. Double-click the header to switch to admin mode.",
      key: "customer-mode",
    });
  } else {
    message.destroy("customer-mode");
    message.info({
      content:
        "You are in admin mode. Double-click the header to switch to customer mode.",
      key: "admin-mode",
    });
  }
};

const adminBackgroundClasses = () => {
  if (process.env.REACT_APP_CUSTOMER_DASHBOARD_BACKENDS) {
    // This is set in api/index on app start
    const backend = sessionStorage.getItem("customer-dashboard-backend");
    if (backend) {
      return {
        "bg-purple-7 before:content-['backend=dev01']": backend === "dev01",
        "bg-aqua-7 before:content-['backend=local']": backend === "local",
        "bg-orange-6 before:content-['backend=mike-dev']":
          backend === "mike-dev",
        "bg-coral-8 before:content-['backend=summer-dev']":
          backend === "summer-dev",
        "bg-lime-8 before:content-['backend=tim-dev']": backend === "tim-dev",
        "before:absolute before:font-mono before:text-xs": true,
      };
    }
  }
  return "bg-purple-7";
};

interface MainLayoutProps {
  adminPage?: boolean;
  children: React.ReactNode;
}

/**
 * Maintains the sider height so that it always touches the bottom of the
 * screen, regardless of if the header is scrolled into view.
 *
 * This returns a ref that should be attached to the `<Header>` component (used
 * by IntersectionObserver to determine where the header is), and an object of
 * style props to use in the `<Sider>` component.
 */
const useSiderStyle = () => {
  const [siderStyle, setSiderStyle] = useState<CSSProperties>({
    position: "sticky",
    top: 0,
    height: "100vh",
    overflow: "auto",
    overscrollBehavior: "contain",
  });
  const handleIntersection = useCallback<IntersectionObserverCallback>(
    (entries) => {
      const headerSpace = entries[0].intersectionRect.height;
      setSiderStyle((prev) => ({
        ...prev,
        height: `calc(100vh - ${headerSpace}px)`,
      }));
    },
    [],
  );
  const ref = useIntersectionObserver<HTMLDivElement>(handleIntersection, {
    threshold: [0, 0.5, 1],
  });
  return { headerRef: ref, siderStyle };
};

/**
 * Main Layout wrapper for app routes. Provides:
 * - Header
 *    - trigger for sider
 *    - app-level actions like logout
 * - Sider
 *    - Routing menu
 */
const MainLayout = ({ children, adminPage }: MainLayoutProps) => {
  const [siderCollapsed, setSiderCollapsed] = useState(
    // This is Sider's lg breakpoint
    // https://github.com/ant-design/ant-design/blob/4.24.7/components/layout/Sider.tsx#L17
    () => window.matchMedia?.("(max-width: 991.98px)").matches,
  );

  // Admin filter + customer mode determine visibility of admin controls and
  // the color of the header. Both are only available to admins.
  const isAdmin = useRoleCheck("biobot-admin");
  const { customerCode, customerMode } = useCustomerOverride();
  const setCustomerOverride = useSetCustomerOverride();
  const canSwitchMode = isAdmin && !!setCustomerOverride && !adminPage;
  const showCustomerBackground = !isAdmin || (canSwitchMode && customerMode);

  const handleHeaderDoubleClick = canSwitchMode
    ? () => {
        if (customerCode) {
          setCustomerOverride((prev) => ({
            ...prev,
            customerMode: !customerMode,
          }));
          showAdminMessage({ customerMode: !customerMode });
        }
      }
    : undefined;
  useEffect(
    () => {
      if (canSwitchMode) {
        showAdminMessage({ customerMode });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { headerRef, siderStyle } = useSiderStyle();

  return (
    <Layout className="min-h-screen">
      <Header
        ref={headerRef}
        className={cx(
          "z-10 pr-11 pl-3 flex justify-between h-24 shadow-lg shadow-navy-3 text-white",
          isAdmin ? adminBackgroundClasses() : undefined,
        )}
        data-test="header-space"
        style={{
          backgroundImage: showCustomerBackground ? `url(${background})` : "",
        }}
        onDoubleClick={handleHeaderDoubleClick}
      >
        <div className="h-full flex items-center">
          <Logo width="200px" data-test="logo" />
          <Button
            type="text"
            data-test="sider-control"
            className="pl-5 -mt-2 text-white text-[20px] hover:bg-transparent"
            onClick={() => setSiderCollapsed((collapsed) => !collapsed)}
          >
            {siderCollapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: "24px" }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: "24px" }} />
            )}
          </Button>
        </div>

        <div className="flex justify-between gap-4">
          {isAdmin && <CustomerOverridePicker />}
          <Button
            type="link"
            data-test="visit-support-page"
            className="h-fit my-auto"
            icon={
              <QuestionCircleOutlined
                style={{ fontSize: "20px", color: "white" }}
              />
            }
            href="https://support.biobot.io/hc/en-us"
            target="_blank"
            rel="noreferrer"
            title="Visit Biobot's Support Page"
          />
          <HeaderDropdownMenu className="h-fit my-auto" />
        </div>
      </Header>
      <Layout>
        <Sider
          theme="light"
          trigger={null}
          collapsible
          collapsedWidth="68"
          collapsed={siderCollapsed}
          breakpoint="lg"
          onBreakpoint={setSiderCollapsed}
          width={235}
        >
          <div style={siderStyle}>
            <NavMenu collapsed={siderCollapsed} />
          </div>
        </Sider>
        <Content className="pl-11 pr-11 py-5">
          {isAdmin && !customerCode ? (
            <Alert message="Please select a customer" />
          ) : (
            children
          )}
          <Footer className="mb-4 pl-0">
            <Space direction="vertical" size="small" data-test="footer-space">
              <div>
                <Link href="mailto:support@biobot.io" target="_blank">
                  support@biobot.io
                </Link>{" "}
                | &copy; {new Date().getFullYear()} Biobot Analytics, Inc.
              </div>
            </Space>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
