import { StringLike, scaleOrdinal } from "@visx/scale";
import type { CurveFactory } from "d3-shape";
import { createContext, useContext, useMemo } from "react";

import colors from "src/colors";

import type { Series } from "./Series";
import type { GapFn } from "./types";
import { useFlatMemo } from "./util";

export const categoricalColors = [
  colors["biobotblue-5"],
  colors["navy-7"],
  colors["green-5"],
  colors["yellow-5"],
  colors["purple-6"],
  colors["aqua-6"],
  colors["orange-5"],
  colors["navy-6"],
  colors["skyblue-7"],
  colors["biobotblue-3"],
  colors["navy-5"],
];

interface AxisTheme {
  axisLineClassName?: string;
  tickClassName?: string;
  tickLineProps?: {
    className?: string;
  };
  hideTicks?: boolean;
  hideAxisLine?: boolean;
  gridLineClassName?: string;
  grid?: boolean;
}

export interface ChartTheme {
  axis?: AxisTheme;
  xAxis?: AxisTheme;
  yAxis?: AxisTheme;
  background?: {
    fill?: string;
    className?: string;
  };
  tooltip?: {
    className?: string;
  };
  hover?: {
    line?: {
      className?: string;
    };
    point?: {
      className?: string;
      r?: number;
    };
  };
  series?: {
    line?: {
      className?: string;
      gap?: GapFn | number;
      unfocusedClassName?: string;
      curve?: CurveFactory;
    };
    area?: {
      className?: string;
      gap?: GapFn | number;
      unfocusedClassName?: string;
      curve?: CurveFactory;
    };
    bar?: { className?: string; unfocusedClassName?: string };
  };
  colors?: string[];
}

export const defaultTheme: ChartTheme = {
  axis: {
    hideTicks: false,
    tickClassName: "text-xs text-neutral-4",
    tickLineProps: { className: "stroke-neutral-2" },
    axisLineClassName: "stroke-neutral-2",
    gridLineClassName: "stroke-neutral-2 stroke-dashed",
  },
  tooltip: {
    className:
      "font-mono text-white text-xs bg-navy-8 opacity-95 rounded shadow p-2",
  },
  hover: {
    line: {
      className: "stroke-neutral-2",
    },
    point: {
      className: "stroke-2 stroke-white",
      r: 4,
    },
  },
  series: {
    line: {
      className: "stroke-2",
    },
  },
  colors: categoricalColors,
};

const ChartThemeContext = createContext<ChartTheme>(defaultTheme);

export const ChartThemeProvider = ChartThemeContext.Provider;
export const useChartTheme = () => useContext(ChartThemeContext);

/**
 * Creates a color scale for the current chart theme given a list of values to
 * use as the domain.
 */
export const useOrdinalColorScale = (domain: StringLike[]) => {
  const { colors: range } = useChartTheme();
  if (!range) {
    throw new Error("Color theme is not defined");
  }
  return useMemo(() => scaleOrdinal({ domain, range }), [domain, range]);
};

/**
 * Applies theme modifications to the full array of series used by a chart
 * (e.g. adding colors from the theme).
 */
export const useThemedSeries = (series: Series[]) => {
  const { colors: themeColors } = useChartTheme();
  return useFlatMemo(
    () =>
      series.map((s, idx) => ({
        ...s,
        color: s.color ?? themeColors?.[idx % themeColors.length],
      })),
    [series, themeColors],
  );
};
