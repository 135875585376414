import norovirusImg from "../../images/norovirus.png";
import AssayCard from "./AssayCard";
import { DownloadLink, ExportButton } from "./Buttons";
import { Dataset, LinkComponentProps, useLinkComponent } from "./links";

const datasets = [
  "norovirusKits",
  "norovirusCountyAverage",
  "norovirusStateAverage",
  "norovirusRegionalAverage",
  "norovirusNationalAverage",
] satisfies Dataset[];

interface NoroDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const NoroDownloads = ({ className, ...props }: NoroDownloadsProps) => {
  const { state, links } = useLinkComponent({ ...props, datasets });
  return (
    <div className={className}>
      <AssayCard
        disabled={state === "no-data" || state === "loading"}
        title="Norovirus"
        image={norovirusImg}
        className="mb-5"
      />
      {state === "loading" && <ExportButton label="loading..." loading />}
      {state === "data" && (
        <>
          <ExportButton
            label="CSV"
            href={links.norovirusKits}
            gaAction="norovirusKits-downloaded"
          />
          {links.norovirusKits && (
            <DownloadLink
              type="default"
              label="Data Dictionary"
              href={`${process.env.PUBLIC_URL}/biobot_norovirus_data_format.xlsx`}
              gaAction="norovirusWastewater-downloaded"
            />
          )}
          <ExportButton
            label="County Subscription"
            href={links.norovirusCountyAverage}
            gaAction="norovirusCountyAverage-downloaded"
          />
          <ExportButton
            label="State Subscription"
            href={links.norovirusStateAverage}
            gaAction="norovirusStateAverage-downloaded"
          />
          <ExportButton
            label="Regional Subscription"
            href={links.norovirusRegionalAverage}
            gaAction="norovirusRegionalAverage-downloaded"
          />
          <ExportButton
            label="National Subscription"
            href={links.norovirusNationalAverage}
            gaAction="norovirusNationalAverage-downloaded"
          />
        </>
      )}
    </div>
  );
};

export default NoroDownloads;
