import rsvImage from "src/images/rsv.png";

import AssayCard from "./AssayCard";
import { ExportButton } from "./Buttons";
import { Dataset, LinkComponentProps, useLinkComponent } from "./links";

const datasets = [
  "rsvKits",
  "rsvCountyAverage",
  "rsvStateAverage",
  "rsvRegionalAverage",
  "rsvNationalAverage",
  "rsvCountyInterpolated",
] satisfies Dataset[];

interface RsvDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const RsvDownloads = ({ className, ...props }: RsvDownloadsProps) => {
  const { state, links } = useLinkComponent({ ...props, datasets });
  return (
    <div className={className}>
      <AssayCard
        disabled={state === "no-data" || state === "loading"}
        title="RSV"
        image={rsvImage}
        className="mb-5"
      />
      {state === "loading" && <ExportButton label="loading..." loading />}
      {state === "data" && (
        <>
          <ExportButton
            label="CSV"
            href={links.rsvKits}
            gaAction="rsvKits-downloaded"
          />
          <ExportButton
            label="County AI Subscription"
            href={links.rsvCountyInterpolated}
            gaAction="rsvCountyInterpolated-downloaded"
          />
          <ExportButton
            label="County Subscription"
            href={links.rsvCountyAverage}
            gaAction="rsvCountyAverage-downloaded"
          />
          <ExportButton
            label="State Subscription"
            href={links.rsvStateAverage}
            gaAction="rsvStateAverage-downloaded"
          />
          <ExportButton
            label="Regional Subscription"
            href={links.rsvRegionalAverage}
            gaAction="rsvRegionalAverage-downloaded"
          />
          <ExportButton
            label="National Subscription"
            href={links.rsvNationalAverage}
            gaAction="rsvNationalAverage-downloaded"
          />
        </>
      )}
    </div>
  );
};

export default RsvDownloads;
