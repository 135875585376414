import { groupBy } from "lodash";
import { useMemo, useState } from "react";

import ExpandButton from "src/components/ExpandButton";

export const ChartLayout = ({ children }: { children: React.ReactNode }) => (
  <div className="flex w-full gap-2">{children}</div>
);

ChartLayout.Body = ({ children }: { children: React.ReactNode }) => (
  <div className="basis-3/4 min-w-0">{children}</div>
);

ChartLayout.Controls = ({ children }: { children: React.ReactNode }) => (
  <div className="flex-1 w-1/4 flex flex-col gap-2">{children}</div>
);

type SmallMultiplesRenderFn<T> = (props: {
  key: string;
  data: T[];
  expandButton: JSX.Element | null;
  isExpanded: boolean;
}) => React.ReactNode;

interface SmallMultiplesProps<T, K extends keyof T> {
  data: T[];
  groupBy: K;
  keys?: T[K][];
  expandedClassName?: string;
  expandDisabled?: boolean;
  renderChart: SmallMultiplesRenderFn<T>;
  renderLabel?: SmallMultiplesRenderFn<T>;
}

const emptyData = [];

/**
 * Renders a the body of a small multiple faceted chart.
 *
 * Data are grouped using the `groupBy` prop and a separate chart is rendered
 * per group. Group keys may optionally be supplied in addition (e.g. if you
 * want to reserve space for empty data charts).
 *
 * With the `expandedClassname` prop, charts are allowed to be expanded and
 * collapsed.
 *
 * @see https://en.wikipedia.org/wiki/Small_multiple
 */
export const SmallMultiples = <T, K extends keyof T>({
  data: data_,
  groupBy: groupBy_,
  keys: keys_,
  expandedClassName,
  expandDisabled,
  renderLabel,
  renderChart,
}: SmallMultiplesProps<T, K>) => {
  const grouped = useMemo(() => groupBy(data_, groupBy_), [data_, groupBy_]);
  const keys = (keys_ ?? Object.keys(grouped)) as (K & string)[];
  const [expandedKey, setExpandedKey] = useState<string>();
  return (
    <>
      {keys.map((key) => {
        const stringKey = key.toString();
        const data = grouped[key] ?? (emptyData as T[]);
        const isExpanded = key === expandedKey;
        const expandButton =
          expandedClassName && !expandDisabled ? (
            <ExpandButton
              isExpanded={isExpanded}
              onClick={() => setExpandedKey(isExpanded ? undefined : key)}
            />
          ) : null;
        return (
          <div key={stringKey} className={isExpanded ? expandedClassName : ""}>
            {renderLabel?.({ key: stringKey, data, expandButton, isExpanded })}
            {renderChart?.({ key: stringKey, data, expandButton, isExpanded })}
          </div>
        );
      })}
    </>
  );
};

const renderGridLabel: SmallMultiplesRenderFn<unknown> = ({
  key,
  expandButton,
}) => (
  <div className="flex justify-between items-center">
    <p className="text-p1 truncate text-navy-9" title={key}>
      {key}
    </p>
    <div className="hidden md:block">{expandButton}</div>
  </div>
);

/**
 * Renders a small multiple faceted chart in a grid with labels and expand
 * buttons.
 *
 * @see SmallMultiples for a full description of props.
 */
SmallMultiples.GridWithExpand = <T, K extends keyof T>(
  props: SmallMultiplesProps<T, K>,
) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <SmallMultiples
      expandedClassName="order-first col-span-full"
      renderLabel={renderGridLabel}
      {...props}
    />
  </div>
);

export default ChartLayout;
